import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CLAIM_ADJUSTER_PARAMS, CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS, CLAIM_REGISTER_PATH } from "src/routes/paths/claim-register.paths";
import { ClaimRegisterSelector } from "../selectors/claim-register.selector";
import { useForm } from "react-hook-form";
import { IClaimAdjusterForm } from "../interfaces/IClaimAdjusterForm";
import { StatusTypeItem } from "src/classes/claims/StatusTypeItem";
import { LoadingFactory } from "src/factory/loading.factory";
import { ClaimRegisterFeature } from "../features/claim-register.feature";
import { AlertFactory } from "src/factory/alert.factory";
import { ClaimAdjuster } from "src/classes/claims/ClaimAdjuster";
import { ClaimRegisterAction } from "../actions/claim-register.action";

export namespace ClaimAdjusterFacade {

  export const useClaimAdjuster = () => {
    const { sectionName, formId, key, pageName } = useParams<string>();
    const location = useLocation();
    const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const adjusterInfo = useSelector(ClaimRegisterSelector.adjusterInfo);
    const navigate = useNavigate();

    /**
     * 
     * @returns 
     */
    const getCurrentQueryParams = (): string => {
      if (query) {
        return `?${query}`;
      }

      return '';
    }

    /**
     * 
     * @param page 
     * @param direction 
     * @param replace 
     */
    const navigatePage = (direction: 'forward' | 'backward', section?: string, replace?: boolean) => {
      navigate(`${CLAIM_REGISTER_PATH.ADJUSTER}/${section}/${formId}/${key}${getCurrentQueryParams()}`, { replace: replace || direction === 'backward' });
    }

    /**
     * 
     */
		useEffect(
			() => {
        const isExemptPage = location.pathname.includes(CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.SYNC);
				if (location.pathname.includes(CLAIM_ADJUSTER_PARAMS.PERSON_MANAGEMENT) && !pageName && !isExemptPage) {
					navigate(`${CLAIM_REGISTER_PATH.ADJUSTER}/${CLAIM_ADJUSTER_PARAMS.PERSON_MANAGEMENT}/${formId}/${key}/person/${CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.PERSONS_LIST}${getCurrentQueryParams()}`, {replace: true})
				}
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [location]
		);


    /**
     * 
     */
    useEffect(
      () => {
        if (adjusterInfo && adjusterInfo.completed && !location.pathname.includes(CLAIM_ADJUSTER_PARAMS.COMPLETED) ) {
          navigate(`${CLAIM_REGISTER_PATH.ADJUSTER}/${CLAIM_ADJUSTER_PARAMS.COMPLETED}/${formId}/${key}${getCurrentQueryParams()}`, { replace: true });
        }
				// eslint-disable-next-line react-hooks/exhaustive-deps
      }, [adjusterInfo, location.pathname]
    );


    return { sectionName, navigatePage }
  }

  /**
   * 
   */
  export const useClaimAdjusterForm = () => {
    const { navigatePage } = useClaimAdjuster();
    const [selectedLiability, setSelectedLiability] = useState<StatusTypeItem>();
    const [selectedCause, setSelectedCause] = useState<StatusTypeItem>();
    const [selectedCircumstance, setSelectedCircumstance] = useState<StatusTypeItem>();
    const [selectedReason, setSelectedReason] = useState<StatusTypeItem>();
		const [showArrivalModal, setShowArrivalModal] = useState<boolean>(false);
    const [showNoticesModal, setShowNoticesModal] = useState<boolean>(false);
    const causesList = useSelector(ClaimRegisterSelector.causesList);
    const circumstancesList = useSelector(ClaimRegisterSelector.circumstancesList);
    const liabilityList = useSelector(ClaimRegisterSelector.liabilityList);
    const reasonsList = useSelector(ClaimRegisterSelector.reasonsList);
    const adjusterInfo = useSelector(ClaimRegisterSelector.adjusterInfo);
    const updating = LoadingFactory.useLoader();
    const location = useLocation();
    const dispatch = useDispatch();
    const { key, formId } = useParams<string>();
    const form = useForm<IClaimAdjusterForm>(
      {
        mode: 'onChange',
        reValidateMode: 'onChange',
      }
    );

    /**
     * 
     * @param id 
     */
    const selectLiabilityById = (id: string): void => {
      if (liabilityList) {
        const liability = liabilityList.find(l => l.id === id);
        if (liability) {
          setSelectedLiability(liability);
        }
      }
    }

    /**
     * 
     * @param id 
     */
    const selectCauseById = (id: string): void => {
      if (causesList) {
        const cause = causesList.find(l => l.id === id);
        if (cause) {
          setSelectedCause(cause);
        }
      }
    }

    /**
     * 
     * @param id 
     */
    const selectCircumstanceById = (id: string): void => {
      if (circumstancesList) {
        const circumstance = circumstancesList.find(c => c.id === id);
        if (circumstance)
          setSelectedCircumstance(circumstance);
      }
    }

    /**
     * 
     * @param id 
     */
    const selectReasonById = (id: string): void => {
      if (reasonsList) {
        const reason = reasonsList.find(l => l.id === id);
        if (reason) {
          setSelectedReason(reason);
        }
      }
    }

    /**
     * 
     */
    const deleteReason = () => {
      setSelectedReason(undefined);
    };

    /**
     * 
     * @param pictures 
     * @param picturesType 
     * @returns 
     */
    const sendCurrentAdjusterInfo = (completed?: boolean, pictures?: string[], picturesType?: TClaimPictureName): Promise<void> => {
      return new Promise<void>(
        async (resolve, reject) => {
          try {
            if (adjusterInfo && key && formId) {
              adjusterInfo.setDeclarations(form.getValues('facts'), selectedCause, selectedCircumstance, selectedLiability, form.getValues('insuredSignature'));
              adjusterInfo.setClousure(form.getValues('closure'), selectedReason);
              adjusterInfo.signature = form.getValues('adjusterSignature');
              updating.set(true);
              const newInfo = await ClaimRegisterFeature.claimAdjusterInfoUpdateFeature(key, adjusterInfo, formId, completed, pictures, picturesType);
              if (newInfo) {
                dispatch(ClaimRegisterAction.setNewClaimAdjusterInfo(newInfo));
              }
              resolve();
            }
          } catch (e) {
            AlertFactory.errorAlert((e as Error).message);
            reject();
          } finally {
            updating.set(false);
          }
        }
      )
    };

    /**
     * 
     */
    const checkAdjusterConditions = () => {
      if (adjusterInfo) {
        const isFormUnfinished = adjusterInfo.isUnfinished();

        if (isFormUnfinished)
          setShowNoticesModal(isFormUnfinished);
        else 
          navigatePage('forward', CLAIM_ADJUSTER_PARAMS.CLAIM_SIGNATURE);
      }
    }

    /**
     * 
     */
    const setCurrentAdjusterInfo = useCallback(
      (adjuster: ClaimAdjuster) => {
        form.setValue('facts', adjuster.peopleFacts!);
        form.setValue('insuredSignature', adjuster.declarationSignature!);
        form.setValue('closure', adjusterInfo?.clousure!);
        form.trigger();
        
        if (adjuster.cause) 
          setSelectedCause(adjuster.cause);
        
        if (adjuster.liability) 
          setSelectedLiability(adjuster.liability);
        
        if (adjuster.reason) 
          setSelectedReason(adjuster.reason);
        
        if (adjuster.claimPeople[0].circumstance)
          setSelectedCircumstance(adjuster.claimPeople[0].circumstance);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps 
      [adjusterInfo]
    );

    /**
     * 
     */
    const validateDeclaration = (): boolean => {
      let isValid: boolean = false;
      if (adjusterInfo?.cause && adjusterInfo.liability) {
        isValid = true;
      }

      return isValid;
    }

    /**
     * 
     */
    const validateClousure = (): boolean => {
      let isValid: boolean = false;
      if (adjusterInfo?.clousure) {
        isValid = true;
      }

      return isValid;
    }

    /**
     * 
     */
    useEffect(
      () => {
        if (adjusterInfo) {
          setCurrentAdjusterInfo(adjusterInfo);
        }
      }, [adjusterInfo, setCurrentAdjusterInfo]
    );

    /**
     * 
     */
    useEffect(
      () => {
        form.trigger();
      }, [form, location.pathname]
    );

    return {
      adjusterInfo,
      updating,
      form,
      checkAdjusterConditions,
      sendCurrentAdjusterInfo,
      deleteReason,
      states: { selectedLiability, selectedCause, selectedCircumstance, selectedReason, showArrivalModal, showNoticesModal, setShowNoticesModal, selectCauseById, selectCircumstanceById, selectLiabilityById, selectReasonById, setShowArrivalModal },
      validate: { validateDeclaration, validateClousure },
      list: { causesList, liabilityList, reasonsList, circumstancesList }
    }
  }
}