
import { Button, InputLabel, Spinner, Text } from "src/components";
import { ClaimPersonSyncFacade, PERSON_SYNC_SECTION } from "src/modules/claim-register/controller/facades/claim-person-sync.facade";
import { IconFactory } from "src/factory/icon.factory";
import ClaimContent from "../../components/ClaimContent/ClaimContent";
import './ClaimPersonSync.scss';
import { QrReader } from "react-qr-reader";
import QRCode from "react-qr-code";
import { Controller } from "react-hook-form";

/**
 *
 */
const ClaimPersonSyncPage = (): JSX.Element => {
	const { form } = ClaimPersonSyncFacade.useClaimPersonSyncForm();
	const { states, loading, backNavigationHandler, onCodeDecoded, createClaimEduaFol, vinculateClaimEduaFol, copyFolId, syncAndRedirect } = ClaimPersonSyncFacade.useClaimPersonSync();

	return (
		<ClaimContent
			title="Crear involucrado"
			subtitle="Tercero Conductor"
			backButton={
				<button type="button" className="claimRegister__button-back" 
					onClick={() => backNavigationHandler()}
				>
					{IconFactory.leftArrowIcon()}
				</button>
			}
		>
			<div className="claimPersonSyncPage">
				{
					states.currentSection === ClaimPersonSyncFacade.SYNC_SECTIONS.SYNC_MANAGEMENT &&
						<section>
							<div className="claimPersonSync__content">
                <Text type="paragraph" align="center" className="claimPersonSync__content__subtitle" >Elige una de las opciones de abajo dependiendo de cuál de las partes llegó antes</Text>
                
								<div className="claimPersonSync__content__buttons">
									<Button
										type="button"
										onClick={() => createClaimEduaFol()}
										loading={loading.value}
									>
										Mostrar QR
									</Button>

									<Button
										type="button"
										fill="outline"
										onClick={() => states.setCurrentSection(ClaimPersonSyncFacade.SYNC_SECTIONS.IMPORT_EDUA_SHEET as PERSON_SYNC_SECTION)}
									>
										Escanear QR
									</Button>
								</div>
							</div>	
						</section>
				}

				{
					states.currentSection === ClaimPersonSyncFacade.SYNC_SECTIONS.GENERATE_EDUA_SHEET &&
						<section>
							<div className="claimPersonSync__content">
								<div className="claimPersonSync__content__wrapper">
									<Text type="paragraph" align="center">Nro. de Folio</Text>
									<Text type="subtitle" align="center" className="claimPersonSync__content__wrapper__value">{states.folId} {IconFactory.clipboardIcon({ onClick: copyFolId })}</Text>
								</div>

								<div className="claimPersonSync__content__wrapper">
									<Text type="paragraph" align="center" className="claimPersonSync__content__wrapper__qrText">QR eDUA</Text>
									<QRCode fgColor="#0D4EC9" value={states.folId ? `${states.folId}|-1|${states.folId}` : ''} size={240} />
								</div>

								<div 
									className={`claimPersonSync__content__wrapper__syncButton ${loading.value && 'claimPersonSync__content__wrapper__syncButton--disabled'}`}
									onClick={syncAndRedirect}
								>
									{
										loading.value ?
											<div className="claimPersonSync__content__wrapper__syncButton__spinner">
												<Spinner />
											</div>
										:
											<>
												{IconFactory.syncFileIcon()}
												<Text type="paragraph" align="center" color="white">Sincronizar tercero</Text>
											</>
									}
								</div>
							</div>
						</section>
				}

				{
					states.currentSection === ClaimPersonSyncFacade.SYNC_SECTIONS.IMPORT_EDUA_SHEET &&
						<section>
							<div className="claimPersonSync__content">
                <Text type="paragraph" align="center" className="claimPersonSync__content__info">Ingresa el número de folio para obtener los datos</Text>

								<Controller
									control={form.control}
									name="sheetNumber"
									rules={{ required: true }}
									render={({ field: { onChange } }) => (
										<InputLabel
											disabled={loading.value}
											onChange={(e) => onChange(e.target.value)}
										>
											Nro de Folio
										</InputLabel>
									)}
								/>

								<div 
									className={`claimPersonSync__content__wrapper__syncButton ${loading.value && 'claimPersonSync__content__wrapper__syncButton--disabled'}`}
									onClick={() => vinculateClaimEduaFol(form.getValues('sheetNumber'))}
								>
									{
										loading.value ?
											<div className="claimPersonSync__content__wrapper__syncButton__spinner">
												<Spinner />
											</div>
										:
											<>
												{/* {IconFactory.syncFileIcon()} */}
												<Text type="paragraph" align="center" color="white">Vincular tercero</Text>
											</>
									}
								</div>
							</div>

								<Text type="paragraph" align="center" className="claimPersonSync__content__info">O también puedes escanear el código QR</Text>

								<div className="claimPersonSync__content__wrapper claimPersonSync__content__wrapper--centered">
									<Text type="paragraph" align="center">QR eDUA</Text>

									<div className="claimPersonSync__content__wrapper__qr">
										{IconFactory.qrIcon({ className: 'claimPersonSync__content__wrapper__qr__qrIcon' })}
										
										{
											states.showQRReader &&
												<QrReader 
													constraints={{ facingMode: 'environment', width: 250, height: 250 }}
													onResult={(result, error) => {
														if (!!result) onCodeDecoded(result.getText());
														if (!!error) console.error('Error: ', error);
													}}
													videoStyle={{ width: '250px', height: '250px' }}
													containerStyle={{ width: '250px', height: '250px', borderRadius: 5 }}
												/>
										}
									</div>

									<Button
										className="claimPersonSync__content__wrapper__scanButton"
										fill="outline"
										onClick={() => states.setShowQRReader(true)}
										loading={loading.value}
									>
										Escanear QR
									</Button>
								</div>
						</section>
				}


			</div>
		</ClaimContent>
	);
};

export default ClaimPersonSyncPage;