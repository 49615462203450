import { Button, Spinner, FormError, Dropdown, ButtonBack } from "src/components";
import { PropsWithChildren, useEffect } from "react";
import { Coverage } from "src/classes/plan/Coverage";
import { Plan } from "src/classes/plan/Plan";
import { Person } from "src/classes/Person";
import { Vehicle } from "src/classes/vehicle/Vehicle";
import { Util } from "src/utils/Util";
import { PlanManagementCouponFacade } from "../../controller/facades/plan-management-coupon.facade";
import { PlanManagementFacade } from "../../controller/facades/plan-management.facade";
import { IconFactory } from "src/factory/icon.factory";
import { PlanCostFacade } from "../../controller/facades/plan-cost.facade";
import PlanCost from "../components/PlanCost/PlanCost";
import PlanDetails from "../components/PlanDetails/PlanDetails";
import PlanContractingButton from "../components/PlanContractingButton/PlanContractingButton";
import { BodyFactory } from "src/factory/body.factory";
import './PlanManagement.scss';

/**
 *
 */
interface IPlanManagement {

  isRenewal?: boolean;

  coupon?: string;

  coverages: Coverage[]

  user: Person;

  vehicle: Vehicle;

  storedPlan?: Plan;
  /**
   * 
   */
  onSelectPlan: (plan: Plan) => void;
  /**
   * 
   */
  onCancel: () => void;
  /**
   * 
   */
  onBackButton?: () => void
}

/**
 *
 */
const PlanManagement = (props: PropsWithChildren<IPlanManagement>): JSX.Element => {
  const { user, vehicle, coverages, coupon, isRenewal, storedPlan } = props;
  const { setElement, clearElement } = BodyFactory.useBody();
  const { plans, currentPlan, selectedPlanPayment, setPlanPayment } = PlanManagementFacade.usePlanManagement(user, vehicle, coverages, coupon, isRenewal);
  const { isUpdating, isLoading } = PlanManagementFacade.usePlanSync();
  const { register, formState, COUPON_ERRORS, currentCoupon, onApplyCoupon } = PlanManagementCouponFacade.usePlanCouponForm(user, vehicle, isRenewal);
  const { currentPaymentMethod, selectedPlanCost, selectCost, additionalCost, selectedDiscountPlanCost, defaultTerm } = PlanCostFacade.usePlanCost(currentPlan, storedPlan);

  /**
   * 
   */
  const selectPlan = (): void => {
    if (currentPlan && selectedPlanPayment) {
      let _currentPlan = currentPlan;
      _currentPlan.selectedPlanPayment = selectedPlanPayment;
      props.onSelectPlan(_currentPlan);
    }
  };

  /**
   * 
   */
  useEffect(
    () => {
      if (currentPlan) {
        selectCost();
      }
    }, [currentPlan, selectCost]
  );

  /**
   * 
   */
  useEffect(
    () => {
      window.scrollTo(0, 0);
    }, []
  );

  useEffect(
    () => {
      setElement(<>
        <div className="planBg">
          <div className="planBg__wrapper">
            <img src={Util.ASSET.PLAN_BG} alt="plan-bg" />
            <div className="planBg__wrapper__color"></div>
          </div>
        </div>
      </>)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  /**
   * 
   */
  useEffect(
    () => {
      return () => clearElement();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="planManagement">

      {
        !isLoading && plans.length > 0 && vehicle ?
          <>

            {
              props.onBackButton &&
              <ButtonBack button text
                to={''}
                onClick={props.onBackButton}
              />
            }

            {
              props.children &&
              <h2 className="planManagement__title text-center">
                {props.children}
              </h2>
            }
            
            <section className="planManagement__section">
              {
                currentPlan &&
                <Dropdown
                  key={currentPlan.id}
                  cancelSearch
                  onChange={(item) => { selectCost(item.id as number); setPlanPayment(item.id as number) }}
                  defaultValue={defaultTerm}
                  items={
                    [
                      { id: PlanCostFacade.PLAN_COST.SUSCRIPTION.id, value: PlanCostFacade.PLAN_COST.SUSCRIPTION.name },
                      { id: PlanCostFacade.PLAN_COST.ANNUAL.id, value: PlanCostFacade.PLAN_COST.ANNUAL.name },
                      ...currentPlan.monthly.costs.map(
                        (cost, index) => {
                          return {
                            id: (index + 1) + PlanCostFacade.PLAN_COST.SUSCRIPTION.id,
                            value: `Pago a ${cost.monthsQuantity} meses (Tarjeta de crédito)`
                          }
                        }
                      )
                    ]}>
                  Forma de pago
                </Dropdown>
              }
            </section>

            <section className="planManagement__section">

              <PlanCost
                key={`${selectedPlanCost?.total}${selectedDiscountPlanCost?.total}`}
                discountCost={selectedDiscountPlanCost}
                cost={selectedPlanCost}
                paymentMethod={currentPaymentMethod}
              />

            </section>

            <section className="planManagement__section planManagement__section planManagement__section planManagement__section--minHeight">
              {
                currentPaymentMethod.code === PlanCostFacade.PAYMENT_METHOD.DEBIT.code && selectedPlanCost?.monthsQuantity === 0 && !selectedPlanCost.isSubscription &&
                <p className="planManagement__section__info text-center">*El pago de contado es hasta 20% más barato que la opción a meses.</p>
              }
              {
                currentPaymentMethod.code === PlanCostFacade.PAYMENT_METHOD.CREDIT.code && selectedPlanCost?.monthsQuantity === 0 &&
                <p className="planManagement__section__info text-center">*El pago de contado es hasta 12.5% más barato que la opción a meses.</p>
              }

              {
                additionalCost &&
                <p className="planManagement__section__info text-center">*Después pagas {additionalCost.getTotal()} por 11 cuotas mensuales.</p>
              }
            </section>

            <section className="planManagement__section">

              <form className="planManagement__couponForm flex align-center" autoComplete="off"
                disabled-form={currentCoupon ? '' : null}
                onSubmit={(e) => { e.preventDefault(); onApplyCoupon() }}
              >
                {IconFactory.couponIcon()}
                <div className="planManagement__couponForm__field flex-grow-100">
                  <p>
                    ¿Tienes un cupón de descuento?
                  </p>
                  <div className="planManagement__couponForm__field__input flex">
                    <input className="flex-grow-100" {...register('coupon', { pattern: Util.PATTERN.PLAN_COUPON })} placeholder="Ingresa el código" maxLength={20} />
                    {
                      !currentCoupon ?
                        <button type="submit" className="planManagement__couponForm__field__input__button">Aplicar</button>
                        :
                        <button type="submit" className="planManagement__couponForm__field__input__button planManagement__couponForm__field__input__button--remove">Eliminar</button>
                    }
                  </div>
                </div>
              </form>
              <div className="absolute width-100 coupon-error">
                <FormError schema={COUPON_ERRORS} type={formState.errors.coupon?.type} />
              </div>

            </section>

            <section className="planManagement__section planManagement__section--short flex">
              <Button id="planManagementButton" type="button"
                loading={isUpdating}
                disabled={isUpdating}
                onClick={() => selectPlan()}>
                Contratar Póliza
              </Button>
            </section>

            {
              (selectedDiscountPlanCost || selectedPlanCost) &&
              <PlanContractingButton
                onClick={() => selectPlan()}
                cost={selectedDiscountPlanCost ? selectedDiscountPlanCost : selectedPlanCost!}
              />
            }

            <PlanDetails
              isRenewal={isRenewal}
              onCancel={props.onCancel}
              vehicle={vehicle}
              plans={plans}
            />
          </>
          :
          <Spinner color="main" />
      }
    </div >
  );
};

export default PlanManagement;