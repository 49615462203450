import { Button, Dropdown, InputArea, Radio, Text } from "src/components";
import ClaimPicture from "../ClaimPicture/ClaimPicture";
import { IconFactory } from "src/factory/icon.factory";
import { Damage } from "src/classes/claims/Damage";
import { ClaimPicturesFacade } from "src/modules/claim-register/controller/facades/claim-person-pictures.facade";
import { StatusTypeItem } from "src/classes/claims/StatusTypeItem";
import { ClaimDamageFacade } from "src/modules/claim-register/controller/facades/claim-damage.facade";
import './ClaimDamage.scss';

/**
 *
 */
interface IClaimDamage {
  /**
   * 
   * @param damage 
   * @returns 
   */
  onSelect: (damage: Damage) => void;
  /**
   * 
   * @param damage 
   * @returns 
   */
  onEdit: (damage: Damage) => void;
  /**
   * 
   */
  formType: 'people' | 'adjuster';
  /**
   * 
   */
  selectedDamage?: Damage;
  /**
   * 
   */
  selectedIndex?: number;
  /**
   * Car or Bike damage type
   */
  damageType?: StatusTypeItem;
  /**
   * 
   */
  hasPreexistence?: boolean;
  /**
   * 
   */
  hasComments?: boolean;
}

/**
 *
 */
const ClaimDamage = (props: IClaimDamage): JSX.Element => {
  const { editDamage, selectDamageTypeById, preexistence, setPreexistence, setDescription, bikeDamages, vehicleDamages, currentType, createDamage, loading } = ClaimDamageFacade.useDamage(props.formType);
  const damagePictures = ClaimPicturesFacade.useClaimPictures(undefined, props.selectedDamage?.pictures);

  return (
    <div className="claimDamage">
      {
        props.damageType &&
        <>
          {
            props.formType === 'people' &&
            <div className="claimDamage__info">
              <span>
                {IconFactory.infoIcon()}
              </span>
              <Text type="label" block>
                <b>Si no sabes el nombre de la parte da&ntilde;ada</b> puedes marcar la primera opci&oacute;n y nosotros lo completaremos.
              </Text>
            </div>
          }

          <div className="claimDamage__list">
            {
              props.damageType.isBikeStatusType() &&
              <Dropdown
                defaultValue={props.selectedDamage ? { id: props.selectedDamage.type.id, value: props.selectedDamage.type.name } : undefined}
                placeholder="¿Dónde está el daño?"
                items={bikeDamages ? bikeDamages.map(b => ({ id: b.id, value: b.name })) : []}
                onChange={(item) => selectDamageTypeById(item.id as string, props.damageType!)}
              />
            }

            {
              props.damageType.isCarStatusType() &&
              <Dropdown
                defaultValue={props.selectedDamage ? { id: props.selectedDamage.type.id, value: props.selectedDamage.type.name } : undefined}
                placeholder="¿Dónde está el daño?"
                items={vehicleDamages ? vehicleDamages.map(b => ({ id: b.id, value: b.name })) : []}
                onChange={(item) => selectDamageTypeById(item.id as string, props.damageType!)}
              />
            }
          </div>

          {
            props.hasPreexistence &&
            <div className="claimDamage__preexistence">
              <Text type="paragraph" align="center">¿Es preexistente el daño?</Text>

              <div>
                <Radio
                  defaultChecked={preexistence === undefined && props.selectedDamage && props.selectedDamage.damagePreexistence === true}
                  name="hasPreexistence"
                  onChange={() => setPreexistence(true)}
                >
                  Si
                </Radio>
                <Radio
                  defaultChecked={preexistence === undefined && props.selectedDamage && props.selectedDamage?.damagePreexistence === false}
                  name="hasPreexistence"
                  onChange={() => setPreexistence(false)}
                >
                  No
                </Radio>
              </div>
            </div>
          }

          {
            props.hasComments &&
            <div>
              <InputArea
                maxLength={5000}
                rows={8}
                defaultValue={props.selectedDamage && props.selectedDamage.description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="NOTAS ADICIONALES"
                name="damageDescArea"
              />
            </div>
          }

          <div className="claimDamage__pictures">
            {
              damagePictures.pictures.map(
                (src, index) => (
                  <div key={`description-image-${index}`} className="claimDamage__pictures__item">
                    <ClaimPicture 
											cancelEdit
											onSelected={(picture) => damagePictures.addPicture(picture, index)}
											defaultPicture={src} 
										/>
                    <Text type="label" align="center" block bold>Foto #{index + 1}</Text>
                  </div>
                )
              )
            }

            <div className="claimPerson__content__form__add-button">
              <button type="button" onClick={() => damagePictures.newPicture()}>
                {IconFactory.addIcon()}
              </button>
              <Text type="label" bold align="center">A&ntilde;adir otra imagen</Text>
            </div>
          </div>

          <div className="claimPerson__content__button-wrapper">
            {
              !props.selectedDamage ?
                <Button type="button"
                  disabled={!currentType || loading.value}
                  loading={loading.value}
                  onClick={() => createDamage(damagePictures.pictures).then((dmg) => props.onSelect(dmg!))}
                >
                  A&ntilde;adir da&ntilde;o
                </Button>
                :
                <Button type="button"
                  disabled={!currentType || loading.value}
                  loading={loading.value}
                  onClick={() => editDamage(damagePictures.pictures, props.selectedDamage!, props.selectedIndex!).then((dmg) => props.onEdit(dmg!))}
                >
                  Editar da&ntilde;o
                </Button>
            }
          </div>
        </>
      }
    </div>
  );
};

export default ClaimDamage;