import { Util } from "src/utils/Util";
import { Outlet } from "react-router-dom";
import { Controller } from "react-hook-form";
import { IconFactory } from "src/factory/icon.factory";
import { ClaimPersonManagementFacade } from "src/modules/claim-register/controller/facades/claim-person-management.facade";
import { Button, Dropdown, Modal, Spinner, Text } from "src/components";
import { ClaimPersonSyncFacade } from "src/modules/claim-register/controller/facades/claim-person-sync.facade";
import { CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS } from "src/routes/paths/claim-register.paths";
import ClaimContent from "../../components/ClaimContent/ClaimContent";
import DeleteItemModal from "../../components/DeleteItemModal/DeleteItemModal";
import ClaimSignature from "../../components/ClaimSignature/ClaimSignature";

import './ClaimPersonManagement.scss';

/**
 *
 */
const ClaimPersonManagementPage = (): JSX.Element => {
	const { sectionNavigation, navigateTo, syncNavigation, panelNavigation } = ClaimPersonManagementFacade.useClaimPersonManagement();
	const { loading, states: syncStates, refolClaim, updateAdjusterInfo } = ClaimPersonSyncFacade.useClaimPersonSync();
	const { 
		registerInfo, 
		adjusterInfo, 
		list, 
		form, 
		states, 
		urlParam, 
		checkSectionAvailability, 
		createClaimRegister, 
		selectClaimRegister, 
		deleteClaimRegister, 
		sendCurrentAdjusterInfo, 
		involvedCreationHandler,
		syncEdua
	} = ClaimPersonManagementFacade.useClaimPersonManagementForm();

	return (
		<div className="claimPersonManagement">
			{
				urlParam.pageName && !urlParam.formType &&
				<>
					{
						urlParam.pageName === CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.PERSONS_LIST &&
						<ClaimContent
							title="Gestión de involucrados"
							subtitle=""
							backButton={
								<button type="button" className="claimRegister__button-back" onClick={panelNavigation}>
									{IconFactory.leftArrowIcon()}
								</button>
							}
						>
							<div className="claimPersonManagement__content">
								<div className="claimPersonManagement__content__wrapper claimPersonManagement__content__wrapper--listMargin">
										<Text className="claimPersonManagement__content__wrapper__subtitle" type="subtitle">Asegurado</Text>
										{
											adjusterInfo &&
												<div className="claimPersonManagement__content__wrapper__person">
													<span className="claimPersonManagement__content__wrapper__person__image" style={{ backgroundImage: `url(${Util.ASSET.CLAIMS_PANEL_PEOPLE})` }}></span>
													<div className="claimPersonManagement__content__wrapper__person__type">
														<Text type="label" uppercase>{adjusterInfo.claimPeople[0].personType.name}</Text>
														<Text type="paragraph" bold uppercase>{adjusterInfo.claimPeople[0].person?.name} {adjusterInfo.claimPeople[0].person?.lastName}</Text>
													</div>
													<div className="claimPersonManagement__content__wrapper__person__buttons">
														<button type="button" className="claimPersonManagement__content__wrapper__person__buttons__edit"
															onClick={() => { selectClaimRegister(adjusterInfo.claimPeople[0]).then(() => navigateTo(ClaimPersonManagementFacade.NAVIGATION_PARAMS.PERSON_EDIT, undefined, 0)) }}
														>
															{IconFactory.editIcon()}
														</button>
													</div>
												</div>
										}

										<Text className="claimPersonManagement__content__wrapper__subtitle" type="subtitle">Involucrados</Text>

										{
											adjusterInfo && adjusterInfo.claimPeople.map((p, i) => {
												if (i !== 0)
													return (
														<div className="claimPersonManagement__content__wrapper__person" key={`person-${i}`}>
															<span className="claimPersonManagement__content__wrapper__person__image" style={{ backgroundImage: `url(${Util.ASSET.CLAIMS_PANEL_PEOPLE})` }}></span>
															<div className="claimPersonManagement__content__wrapper__person__type">
																<Text type="label" uppercase>{p.personType.name}</Text>
																<Text type="paragraph" bold uppercase>{p.person?.name} {p.person?.lastName}</Text>
															</div>
															<div className="claimPersonManagement__content__wrapper__person__buttons">
																<button type="button" className="claimPersonManagement__content__wrapper__person__buttons__edit"
																	onClick={() => { selectClaimRegister(p).then(() => navigateTo(ClaimPersonManagementFacade.NAVIGATION_PARAMS.PERSON_EDIT, undefined, i)) }}
																>
																	{IconFactory.editIcon()}
																</button>
																{
																	(!p.personType.isNA() && !p.isPrior) &&
																		<button type="button" className="claimPersonManagement__content__wrapper__person__buttons__remove"
																			onClick={() => { states.setDeleteItemIndex(i); states.setShowDeleteModal(true) }}
																		>
																			{IconFactory.removeIcon()}
																		</button>
																}
															</div>
														</div>
													);
												else
											    return null;
											})
										}

										<div className="claimPersonManagement__content__wrapper__add-button">
											<button type="button"
												onClick={() => { states.setPersonType(''); navigateTo(ClaimPersonManagementFacade.NAVIGATION_PARAMS.PERSON_TYPE) }}
											>
												{IconFactory.addIcon()}
												<Text type="paragraph" align="center" uppercase>
													A&ntilde;adir
													{adjusterInfo && adjusterInfo.claimPeople.length > 0 ? <>&nbsp;otro&nbsp;</> : <>&nbsp;</>}
													involucrado
												</Text>
											</button>
										</div>

										{
											(adjusterInfo && adjusterInfo.claimPeople[0].folId) &&
												<div  className="claimPersonManagement__content__syncEDUA">
													<div 
														className={`claimPersonManagement__content__syncEDUA__button ${(states.loadingEDUASync.value || adjusterInfo.isInvolvedsCompleted) && 'claimPersonManagement__content__syncEDUA__button--disabled'}`}
														onClick={syncEdua}
													>
														{
															states.loadingEDUASync.value ?
                                <div className="claimPersonManagement__content__syncEDUA__button__spinner">
																	<Spinner color="white"/>
																</div>
															:
                                <>
                                  {IconFactory.syncFileIcon()}
                                  <Text type="paragraph" align="center" color="white">Sincronizar eDUA</Text>
                                </>
                            }
													</div>
												</div>
										}
								</div>

								<div className="claimRegister__button-wrapper">
									<section className="claimPersonManagement__content__info">
										<span className="claimPersonManagement__content__info__warningImage" style={{ backgroundImage: `url(${Util.ASSET.WARNING_IMAGE})` }}></span>
										<div>
											<Text type="paragraph">Primero completa los datos de NA y después añade al resto de involucrados.</Text>
										</div>
									</section>

									<Button
										type="button"
										disabled={!adjusterInfo || adjusterInfo.claimPeople.length === 0 || loading.value}
                    loading={loading.value}
										onClick={() =>  {
											adjusterInfo && adjusterInfo.hasFolId() && !adjusterInfo.isInvolvedsCompleted ?
												syncStates.setShowRefolModal(true)
											:
                        updateAdjusterInfo(true).then(panelNavigation);
										}}
									>
										Completar
									</Button>
								</div>

								<DeleteItemModal 
									type="people" 
									show={states.showDeleteModal}
									onConfirmDelete={deleteClaimRegister}
									onCancel={() => states.setShowDeleteModal(false)}
								/>

								<Modal show={syncStates.showRefolModal} position="center">
									<div className="claimAdjuster__arrivalModal">
										<Text type="subtitle" align="center">Al continuar ya no se podrán volver a sincronizar los terceros con eDUA. ¿Deseas continuar?</Text>
										
										<div className="claimAdjuster__arrivalModal__buttonsWrapper">
											<Button 
												type="button" 
												loading={loading.value}
                        disabled={loading.value}
												onClick={() => refolClaim().then(panelNavigation)}
											>
												Sí, continuar
											</Button>

											<Button 
												fill="outline"
												disabled={loading.value}
												onClick={() => syncStates.setShowRefolModal(false)}
											>
												Cancelar
											</Button>
										</div>
									</div>
								</Modal>
							</div>
						</ClaimContent>
					}

					{
						urlParam.pageName === CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.PERSON_TYPE &&
						<ClaimContent
							title="Agregar involucrado"
							subtitle={!states.showInvolvedCreationType ? 'Selecciona el tipo de persona' : 'Tercero Conductor'}
							backButton={
								<button type="button" className="claimRegister__button-back" 
									onClick={() => { states.showInvolvedCreationType ? states.setShowInvolvedCreationType(false) : navigateTo(ClaimPersonManagementFacade.NAVIGATION_PARAMS.MANAGEMENT) }}
								>
									{IconFactory.leftArrowIcon()}
								</button>
							}
						>
							<div className={"claimPersonManagement__content"}>
								{
									!states.showInvolvedCreationType &&
										<form className="claimPerson__content__form">
											<div className="claimPerson__content__form__field">
												<Dropdown
													placeholder="Selecciona el tipo de persona"
													defaultValue={states.personType ? { id: states.personType, value: states.personType } : undefined}
													items={list.personTypes ? list.personTypes.filter(t => !t.isNA()).map(t => ({ id: t.id, value: t.name })) : []}
													onChange={(item) => states.setPersonType(item.value)}
												/>
											</div>
										</form>
								}

								{
									states.showInvolvedCreationType &&
										<div className="claimPersonManagement__content__involvedCreation">
											<Button
												type="button"
												onClick={() => syncNavigation()}
												disabled={adjusterInfo && !adjusterInfo.eDUAInvolvedCreationAllowed()}
											>
												Crear por eDUA
											</Button>

											<Button
												type="button"
												fill="outline"
												onClick={() => { createClaimRegister(); navigateTo(ClaimPersonManagementFacade.NAVIGATION_PARAMS.PERSON_EDIT) }}
											>
												Crear manualmente
											</Button>
										</div>
								}

								{
									!states.showInvolvedCreationType &&
										<div className="claimRegister__button-wrapper">
											<Button
												type="button"
												disabled={states.personType === undefined}
												onClick={involvedCreationHandler}
											>
												Continuar
											</Button>
										</div>
								}
							</div>
						</ClaimContent>
					}

					{
						urlParam.pageName === CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.GENERAL &&
						<ClaimContent
							title={`Datos del ${registerInfo?.personType.name}`}
							subtitle="Por favor completa todas las secciones para poder continuar"
							backButton={
								<button type="button" className="claimRegister__button-back" onClick={() => navigateTo(ClaimPersonManagementFacade.NAVIGATION_PARAMS.MANAGEMENT, false, undefined, true)}>
									{IconFactory.leftArrowIcon()}
								</button>
							}
						>
							<div className="claimPersonManagement__content">
								<div className="claimPersonManagement__content__panel">

									{
										states.panelSections && states.panelSections.map((section, idx) => {
											return (
												<div 
													className={`claimPersonManagement__content__panel__section ${(!checkSectionAvailability(section.uid) || states.loadingEDUASync.value) && 'claimPersonManagement__content__panel__section--disable'}`} 
													key={`involved-${idx}`}
													onClick={() => sectionNavigation(section.uid)}
												>
													<div className="claimPersonManagement__content__panel__section__titleWrapper">
														<div className="claimPersonManagement__content__panel__section__titleWrapper__position">
															<Text type="title" align="right">{idx + 1}</Text>
														</div>
														<div className="claimPersonManagement__content__panel__section__titleWrapper__title">
															<Text type="title">{section.title}</Text>
															<Text type="title">{section.subtitle}</Text>
														</div>
													</div>

													<div className="claimPersonManagement__content__panel__section__image">
														<span className="claimRegister__data-panel__section__icon__bg" style={{ backgroundImage: `url(${section.asset})` }}></span>
													</div>

													<div className="claimPersonManagement__content__panel__section__bottom">
														<div className="claimPersonManagement__content__panel__section__bottom__edit">
															<Text type="paragraph" align="center" color="main">Editar</Text>
														</div>
													</div>
												</div>
											)
										})
									}
								</div>

								<div className="claimRegister__button-wrapper">
									<Button type="button"
										disabled={!form.formState.isValid || (registerInfo?.person?.name !== undefined && !registerInfo.person.name) || states.loadingEDUASync.value}
										onClick={() => 
											registerInfo && adjusterInfo && adjusterInfo.allowedSignature(registerInfo) ?
												navigateTo(ClaimPersonManagementFacade.NAVIGATION_PARAMS.SIGNATURE)
											:
												navigateTo(ClaimPersonManagementFacade.NAVIGATION_PARAMS.MANAGEMENT, true, undefined, true)
										}
									>
										Siguiente
									</Button>
								</div>
							</div>
						</ClaimContent>
					}

					{
						urlParam.pageName === CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.SIGNATURE &&
						<ClaimContent
							title="Firma digital"
							subtitle="Firma de aceptación de daños y servicios"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => navigateTo(ClaimPersonManagementFacade.NAVIGATION_PARAMS.PERSON_EDIT)}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonManagement__content">
								<div className="claimPersonManagement__content__wrapper">
									<div className="claimPersonManagement__content__wrapper__signatureText">
										<Controller
											control={form.control}
											name="signature"
											rules={{
												required: true
											}}
											render={({ field: { onChange } }) => (
												<ClaimSignature
													label="Firma del involucrado"
													onSign={(signature) => onChange(signature!)}
													personFullName={`${registerInfo?.person.name} ${registerInfo?.person.lastName || ''} ${registerInfo?.person.secondLastName || ''}`}
												/>
											)}
										/>
									</div>
								</div>

								<div className="claimRegister__button-wrapper">
									<Button 
										type="button" 
										disabled={!form.formState.isValid || states.isUpdatingForm.value}
                    loading={states.isUpdatingForm.value}
										onClick={() => sendCurrentAdjusterInfo().then(() => navigateTo(ClaimPersonManagementFacade.NAVIGATION_PARAMS.MANAGEMENT, true, undefined, true))}
									>
										Validar
									</Button>
								</div>
							</div>
						</ClaimContent>
					}

					{
						urlParam.pageName === CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS.EDIT &&
						<Outlet />
					}
				</>
			}
		</div>
	);
};

export default ClaimPersonManagementPage;