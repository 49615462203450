import { createAction, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { ClaimAdjuster } from "src/classes/claims/ClaimAdjuster";
import { ClaimRegister } from "src/classes/claims/ClaimRegister";

/**
 *
 */
export namespace ClaimRegisterAction {

  export const fillLists = createAction('FILL_CLAIMS_LISTS');

  export const fillListsWithPayload: ActionCreatorWithPayload<{adjuster: ClaimAdjuster}> = createAction('FILL_PAYLOAD_LISTS');

  export const claimPersonForm: ActionCreatorWithPayload<{id: string, key: string}> = createAction('CLAIMS_PERSON_FORM');

  export const setNewClaimRegisterInfo: ActionCreatorWithPayload<ClaimRegister> = createAction('SET_NEW_CLAIM_PERSON_INFO');

  export const setNewClaimAdjusterInfo: ActionCreatorWithPayload<ClaimAdjuster> = createAction('SET_NEW_CLAIM_ADJUSTER_INFO');

  export const claimAdjusterForm: ActionCreatorWithPayload<{id: string, key: string}> = createAction('CLAIMS_ADJUSTER_FORM');
}