import { CLAIM_ADJUSTER_PERSON_EDIT_PARAMS } from "src/routes/paths/claim-register.paths";
import { Button, Dropdown, InputDate, InputLabel, InputPhone, Radio, Text } from "src/components";
import { Util } from "src/utils/Util";
import ClaimContent from "../../components/ClaimContent/ClaimContent";

import { Controller } from "react-hook-form";
import { MapFactory } from "src/factory/map.factory";
import ClaimPicture from "../../components/ClaimPicture/ClaimPicture";
import { ClaimPersonManagementFacade } from "src/modules/claim-register/controller/facades/claim-person-management.facade";
import { ClaimPicturesFacade } from "src/modules/claim-register/controller/facades/claim-person-pictures.facade";

import './ClaimPersonGeneral.scss';
import { IconFactory } from "src/factory/icon.factory";
import DeleteItemModal from "../../components/DeleteItemModal/DeleteItemModal";

/**
 *
 */
const ClaimPersonGeneralPage = (): JSX.Element => {
	
	/**
	 * 
	 */
	const { personalDataForwardNavigation, personalDataBackwardNavigation, directNavigation } = ClaimPersonManagementFacade.useClaimPersonManagement();
  const { form, formAddress, urlParam, states, list, adjusterInfo, registerInfo, sendCurrentAdjusterInfo } = ClaimPersonManagementFacade.useClaimPersonManagementForm();
	const licensePictures = ClaimPicturesFacade.useClaimPictures(2, registerInfo?.licensePictures);
  const inePictures = ClaimPicturesFacade.useClaimPictures(2, registerInfo?.inePictures);
  const drivingPermissionPicture = ClaimPicturesFacade.useClaimPictures(undefined, registerInfo?.drivingPermissionPictures);

  return (
    <div className="claimPersonGeneral">

			{
        urlParam.editPage &&
        <>
					{
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA &&
						<ClaimContent
							title="Información general"
							subtitle="Datos generales"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => personalDataBackwardNavigation(CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA)}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonManagement__content">
								<div className="claimPersonManagement__content__wrapper">
									<div className="claimPersonManagement__content__wrapper__title">
										<span></span>
										<Text type="subtitle" className="claimPersonManagement__content__wrapper__title__text">Datos de la persona</Text>
									</div>

									<form className="claimPersonManagement__content__wrapper__form">
										<div className="claimPersonManagement__content__wrapper__form__field">
											<Controller
												control={form.control}
												name="name"
												rules={
													{
														required: true,
														pattern: Util.PATTERN.PERSON_NAMES
													}
												}
												render={({ field: { onChange } }) => (
													<InputLabel
														errors={!!form.formState.errors.name && form.formState.dirtyFields.name}
														maxLength={200}
														defaultValue={form.getValues('name')}
														onChange={(e) => onChange(e.target.value)}
														tabIndex={1}
													>
														Nombre <span>*</span>
													</InputLabel>
												)}
											/>
										</div>

										<div className="claimPersonManagement__content__wrapper__form__field">
											<Controller
												control={form.control}
												name="lastName"
												rules={
													{
														required: true,
														pattern: Util.PATTERN.PERSON_NAMES
													}
												}
												render={({ field: { onChange } }) => (
													<InputLabel
														errors={!!form.formState.errors.lastName && form.formState.dirtyFields.lastName}
														maxLength={200}
														defaultValue={form.getValues('lastName')}
														onChange={(e) => onChange(e.target.value)}
														tabIndex={2}
													>
														Apellido paterno
													</InputLabel>
												)}
											/>
										</div>

										<div className="claimPersonManagement__content__wrapper__form__field">
											<Controller
												control={form.control}
												name="secondLastName"
												rules={
													{
														required: true,
														pattern: Util.PATTERN.PERSON_NAMES
													}
												}
												render={({ field: { onChange } }) => (
													<InputLabel
														errors={!!form.formState.errors.secondLastName && form.formState.dirtyFields.secondLastName}
														maxLength={200}
														defaultValue={form.getValues('secondLastName')}
														onChange={(e) => onChange(e.target.value)}
														tabIndex={3}
													>
														Apellido materno
													</InputLabel>
												)}
											/>
										</div>

										<div className="claimPersonManagement__content__wrapper__form__field">
											<Controller
												control={form.control}
												name="phone"
												rules={
													{
														required: true,
														pattern: Util.PATTERN.NUMBERS,
														maxLength: 10,
														minLength: 10
													}
												}
												render={({ field: { onChange } }) => (
													<InputPhone
														lockCountryCode
														placeholder="Teléfono"
														errors={!!form.formState.errors.phone && form.formState.dirtyFields.phone}
														defaultValue={form.getValues('phone')}
														onCountryCodeSelected={(code) => form.setValue('countryCode', code)}
														onChange={(e) => onChange(e.target.value)}
														tabIndex={4}
													>
														Tel&eacute;fono
													</InputPhone>
												)}
											/>
										</div>

										<div className="claimPersonManagement__content__wrapper__form__field">
											<Controller
												control={form.control}
												name="email"
												rules={
													{
														required: true,
														pattern: Util.PATTERN.EMAIL
													}
												}
												render={({ field: { onChange } }) => (
													<InputLabel
														type="email"
														placeholder="micorreo@correo.com"
														errors={!!form.formState.errors.email && form.formState.dirtyFields.email}
														maxLength={200}
														defaultValue={form.getValues('email')}
														onChange={(e) => onChange(e.target.value)}
														tabIndex={5}
													>
														Correo electr&oacute;nico
													</InputLabel>
												)}
											/>
										</div>

										<div className="claimPersonManagement__content__wrapper__form__field">
											<Controller
												control={form.control}
												name="birthdate"
												rules={
													{
														required: true,
													}
												}
												render={({ field: { onChange } }) => (
													<InputDate
														errors={(!!form.formState.errors.birthdate && form.formState.dirtyFields.birthdate)}
														defaultValue={form.getValues('birthdate') ? form.getValues('birthdate') : ''}
														onSelectDate={(date) => { onChange(date.toDateString()); form.trigger('birthdate') }}
														position="top"
														tabIndex={6}
													>
														Fecha de nacimiento
													</InputDate>
												)}
											/>
										</div>

										<Dropdown
											cancelSearch
											items={ClaimPersonManagementFacade.GENDERS}
											onChange={(item) => { form.setValue('gender', item.id as number); form.trigger('gender') }}
											defaultValue={form.getValues('gender') ? { id: form.getValues('gender'), value: ClaimPersonManagementFacade.GENDERS.find(i => i.id === form.getValues('gender'))?.value || ''} : undefined}
											placeholder="Selecciona el tipo"
										>
											Sexo
										</Dropdown>
									</form>

									{
										adjusterInfo && !adjusterInfo.claimType.isClaimRobbery() &&
										<>										
											<div className="claimPersonManagement__content__wrapper__title">
												<span></span>
												<Text type="subtitle" className="claimPersonManagement__content__wrapper__title__text">Datos de domicilio</Text>
											</div>

											<form className="claimPersonManagement__content__wrapper__form">
												{
													registerInfo && registerInfo.personType.isNA() &&
													<>
														<Text type="paragraph" align="center">¿Es esta la dirección del conductor?</Text>
														<div className="claimPersonManagement__content__wrapper__form__field claimPersonManagement__content__wrapper__form__field--center">
															<Controller
																control={form.control}
																name="savedAddress"
																rules={{
																	required: false
																}}
																render={({ field: { onChange } }) => (
																	<>
																		<Radio
																			defaultChecked={form.getValues('savedAddress') === true}
																			name="savedAddress"
																			onChange={() => { onChange(true); formAddress.setShowUserAddress(true) }}
																		>
																			Sí
																		</Radio>
																		<Radio
																			defaultChecked={form.getValues('savedAddress') === false}
																			name="savedAddress"
																			onChange={() => { 
																				onChange(false); 
																				form.trigger('savedAddress'); 
																				form.setValue('postalCode', '');
																				form.setValue('municipality', '');
																				form.setValue('state', '');
																				form.trigger(); 
																				formAddress.setShowUserAddress(false) 
																			}}
																		>
																			No
																		</Radio>
																	</>
																)}
															/>
														</div>
													</>
												}

												{
													(!formAddress.showUserAddress || registerInfo?.personType.isThirdPartyDriver()) &&
													<>
														{
															registerInfo && registerInfo.personType.isNA() &&
															<div className="claimPersonManagement__content__wrapper__form__field">
																<MapFactory.Map label="Dirección"
																	onSelect={(address) => formAddress.onSelectAddress(address)}
																	defaultCoords={{ lat: formAddress.currentAddress?.latitude!, lng: formAddress.currentAddress?.longitude! }} 
																	hasErrors={(form.getValues('postalCode') !== undefined) && (form.getValues('postalCode').length > 0) && !states.isPostalCodeValid}
																/>
															</div>
														}										

														<div className="claimPersonManagement__content__wrapper__form__field">
															<Controller
																control={form.control}
																name="postalCode"
																rules={
																	{
																		required: false,
																		pattern: Util.PATTERN.NUMBERS,
																		maxLength: 8
																	}
																}
																render={({ field: { onChange } }) => (
																	<InputLabel
																		errors={(!!form.formState.errors.postalCode && form.formState.dirtyFields.postalCode) || ((form.getValues('postalCode') !== undefined) && (form.getValues('postalCode').length > 0) && !states.isPostalCodeValid)}
																		disabled={registerInfo?.personType.isNA()}
																		defaultValue={form.getValues('postalCode')}
																		key={`postal-code-${formAddress.loadingPostalCodeInfo.value}`}
																		maxLength={8}
																		onChange={(e) => { onChange(e.target.value); form.trigger('postalCode'); }}
																		number
																		tabIndex={7}
																	>
																		C&oacute;digo postal
																	</InputLabel>
																)}
															/>
														</div>

														<div className="claimPersonManagement__content__wrapper__form__field">
															<Controller
																control={form.control}
																name="municipality"
																rules={
																	{
																		required: true,
																	}
																}
																render={() => (
																	<InputLabel disabled
																		defaultValue={form.getValues('municipality')}
																		loading={formAddress.loadingPostalCodeInfo.value}
																		errors={(form.getValues('postalCode') !== undefined) && (form.getValues('postalCode').length > 0) && !states.isPostalCodeValid}
																	>
																		Municipio
																	</InputLabel>
																)}
															/>
														</div>

														<div className="claimPersonManagement__content__wrapper__form__field">
															<Controller
																control={form.control}
																name="state"
																rules={
																	{
																		required: true,
																	}
																}
																render={() => (
																	<InputLabel disabled
																		defaultValue={form.getValues('state')}
																		loading={formAddress.loadingPostalCodeInfo.value}
																		errors={(form.getValues('postalCode') !== undefined) && (form.getValues('postalCode').length > 0) && !states.isPostalCodeValid}
																	>
																		Estado
																	</InputLabel>
																)}
															/>
														</div>
													</>
												}

												{
													(formAddress.showUserAddress || form.getValues('savedAddress')) && registerInfo && registerInfo.person?.profile?.address && !registerInfo?.personType.isThirdPartyDriver() &&
														<>
															<div className="claimPerson__content__form__field">
																<Text type="label" align="center" uppercase block>Direcci&oacute;n</Text>
																<Text type="paragraph" align="center" bold>{registerInfo.person.profile.address.name}</Text>
															</div>
															<div className="claimPerson__content__form__field">
																<Text type="label" align="center" uppercase block>C&oacute;digo postal</Text>
																<Text type="paragraph" align="center" bold>{registerInfo.person.profile.address.postalCode}</Text>
															</div>
															<div className="claimPerson__content__form__field">
																<Text type="label" align="center" uppercase block>Municipio</Text>
																<Text type="paragraph" align="center" bold>{registerInfo.person.profile.address.municipality}</Text>
															</div>
															<div className="claimPerson__content__form__field">
																<Text type="label" align="center" uppercase block>Estado</Text>
																<Text type="paragraph" align="center" bold>{registerInfo.person.profile.address.state}</Text>
															</div>
														</>
												}
											</form>
										</>
									}
								</div>

								<div className="claimRegister__button-wrapper">
									<Button 
										type="button"
										disabled={!form.getValues('name') 
											|| (registerInfo?.personType.isNA() && !formAddress.showUserAddress && (!form.getValues('postalCode') || !states.isPostalCodeValid))
											|| (!!form.getValues('postalCode') && formAddress.loadingPostalCodeInfo.value)
                      || states.isUpdatingForm.value
										}
										onClick={() => sendCurrentAdjusterInfo().then((idx) => personalDataForwardNavigation(ClaimPersonManagementFacade.GENERAL_NAVIGATION_PARAMS.DATA, idx as number))}
										loading={formAddress.loadingPostalCodeInfo.value || states.isUpdatingForm.value}
									>
										Siguiente
									</Button>
								</div>
							</div>
						</ClaimContent>
					}

					{
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LICENSE_PICTURES && 
						<ClaimContent
							title="Información general"
							subtitle="Fotos de licencia"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => personalDataBackwardNavigation(ClaimPersonManagementFacade.GENERAL_NAVIGATION_PARAMS.LICENSE_PICTURES)}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonManagement__content">
								<form className="claimPersonManagement__content__form">
									<ClaimPicture
										defaultPicture={licensePictures.pictures[0]}
										onSelected={(picture) => licensePictures.setPictureValue(0, picture)}
									/>
									<Text type="paragraph" align="center" block bold>Frente de tu licencia</Text>
									<ClaimPicture
										defaultPicture={licensePictures.pictures[1]}
										onSelected={(picture) => licensePictures.setPictureValue(1, picture)}
									/>
									<Text type="paragraph" align="center" block bold>Reverso de tu licencia</Text>
								</form>

								<div className="claimRegister__button-wrapper">
									<Button type="button"
										disabled={states.isUpdatingForm.value}
										loading={states.isUpdatingForm.value}
										onClick={() => sendCurrentAdjusterInfo(false, licensePictures.pictures, 'license').then(() => personalDataForwardNavigation(ClaimPersonManagementFacade.GENERAL_NAVIGATION_PARAMS.LICENSE_PICTURES))}
									>
										Siguiente
									</Button>
									<Text type="label" align="center" block className="claimRegister__button-wrapper__info">
										No tengo mi licencia.
										<button type="button" onClick={() => directNavigation(ClaimPersonManagementFacade.GENERAL_NAVIGATION_PARAMS.INE)}>&nbsp;Click aqu&iacute;</button>
									</Text>
								</div>
							</div>
						</ClaimContent>
					}

					{
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.INE &&
						<ClaimContent
							title="Información general"
							subtitle="Fotos del INE"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => {
									personalDataBackwardNavigation(ClaimPersonManagementFacade.GENERAL_NAVIGATION_PARAMS.INE)
								}}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonManagement__content">
								<form className="claimPersonManagement__content__form">
									<ClaimPicture
										defaultPicture={inePictures.pictures[0]}
										onSelected={(picture) => inePictures.setPictureValue(0, picture)}
									/>
									<Text type="paragraph" align="center" block bold>Frente del INE</Text>
									<ClaimPicture
										defaultPicture={inePictures.pictures[1]}
										onSelected={(picture) => inePictures.setPictureValue(1, picture)}
									/>
									<Text type="paragraph" align="center" block bold>Reverso del INE</Text>
								</form>

								<div className="claimRegister__button-wrapper">
									<Button type="button"
										disabled={states.isUpdatingForm.value}
										loading={states.isUpdatingForm.value}
										onClick={() => sendCurrentAdjusterInfo(false, inePictures.pictures, 'ine').then(() => {
											personalDataForwardNavigation(ClaimPersonManagementFacade.GENERAL_NAVIGATION_PARAMS.INE)
										})}
									>
										{ registerInfo && (registerInfo.personType.isNA() || registerInfo.personType.isThirdPartyDriver() || registerInfo?.personType.isCyclistPersonType()) ? 'Siguiente' : 'Completar' }
									</Button>

									{
										(registerInfo && (registerInfo.personType.isNA() || registerInfo.personType.isThirdPartyDriver())) &&
											<Text type="label" align="center" block className="claimRegister__button-wrapper__info">
												Ir a datos de licencia.
												<button type="button" onClick={() => directNavigation(ClaimPersonManagementFacade.GENERAL_NAVIGATION_PARAMS.LICENSE_PICTURES)}>&nbsp;Click aqu&iacute;</button>
											</Text>
									}
								</div>
							</div>
						</ClaimContent>
					}

					{
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DRIVING_CARD &&
						<ClaimContent
							title="Información general"
							subtitle="Foto de la tarjeta de circulación"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => 
									personalDataBackwardNavigation(ClaimPersonManagementFacade.GENERAL_NAVIGATION_PARAMS.DRIVING_CARD)
								}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonManagement__content">

								<form className="claimPersonManagement__content__form">
									<ClaimPicture
										defaultPicture={drivingPermissionPicture.pictures[0]}
										onSelected={(picture) => drivingPermissionPicture.setPictureValue(0, picture)}
									/>
									<Text type="paragraph" align="center" block bold>Frente de tarjeta de circulación</Text>
								</form>

								<div className="claimRegister__button-wrapper">
									<Button type="button"
										disabled={states.isUpdatingForm.value}
										loading={states.isUpdatingForm.value}
										onClick={() => sendCurrentAdjusterInfo(false, drivingPermissionPicture.pictures, 'drivingPermission').then(() => {
											personalDataForwardNavigation(ClaimPersonManagementFacade.GENERAL_NAVIGATION_PARAMS.DRIVING_CARD)
										})}
									>
										Siguiente
									</Button>
								</div>
							</div>
						</ClaimContent>
					}

					{
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LICENSE_DATA &&
						<ClaimContent
							title="Información general"
							subtitle="Licencia"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => 
										personalDataBackwardNavigation(ClaimPersonManagementFacade.GENERAL_NAVIGATION_PARAMS.LICENSE_DATA)
									}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonManagement__content">
								<div className="claimPersonManagement__content__wrapper">
									<div className="claimPersonManagement__content__wrapper__title">
										<span></span>
										<Text type="subtitle" className="claimPersonManagement__content__wrapper__title__text">Datos de la licencia</Text>
									</div>

									<form className="claimPersonManagement__content__wrapper__form">
										<div className="claimPersonManagement__content__wrapper__form__field">
											<Controller
												control={form.control}
												name="licenseNumber"
												rules={
													{
														required: true,
													}
												}
												render={({ field: { onChange } }) => (
													<InputLabel
														errors={(!!form.formState.errors.licenseNumber && form.formState.dirtyFields.licenseNumber)}
														defaultValue={form.getValues('licenseNumber')}
														onChange={(e) => onChange(e.target.value)}
													>
														Nro de licencia
													</InputLabel>
												)}
											/>
										</div>

										{
											registerInfo && !registerInfo.personType.isThirdPartyDriver() &&
											<>											
												<div className="claimPersonManagement__content__wrapper__form__field">
													<Dropdown
														placeholder="Selecciona el estado de emisión"
														defaultValue={form.getValues('licenseState') ? { id: form.getValues('licenseState'), value: form.getValues('licenseState') } : undefined}
														items={list.countryStates ? list.countryStates.map(s => ({ id: s.state, value: s.state })) : []}
														onChange={(item) => { form.setValue('licenseState', item.value); form.trigger('licenseState') }}
													>
														Estado de emisi&oacute;n
													</Dropdown>
												</div>

												<div className="claimPersonManagement__content__wrapper__form__field">
													<Dropdown
														cancelSearch
														placeholder="Selecciona el tipo de licencia"
														defaultValue={states.selectedLicenseType ? { id: states.selectedLicenseType.id, value: states.selectedLicenseType.name } : undefined}
														items={list.licenceTypes ? list.licenceTypes.map(l => ({ id: l.id, value: l.name })) : []}
														onChange={(item) => states.selectLicenceTypeById(item.id as string)}
													>
														Tipo de licencia
													</Dropdown>
												</div>

												<div className="claimPersonManagement__content__wrapper__form__field">
													<Controller
														control={form.control}
														name="licenseStartDate"
														rules={
															{
																required: true,
															}
														}
														render={({ field: { onChange } }) => (
															<InputDate
																errors={(!!form.formState.errors.licenseStartDate && form.formState.dirtyFields.licenseStartDate)}
																defaultValue={form.getValues('licenseStartDate') ? form.getValues('licenseStartDate') : ''}
																minDate={new Date(2000, 0)}
																onSelectDate={(date) => { onChange(date.toDateString()); form.trigger('licenseStartDate') }}
																position="top"
															>
																Inicio vigencia
															</InputDate>
														)}
													/>
												</div>

												<div className="claimPersonManagement__content__wrapper__form__field">
													<Controller
														control={form.control}
														name="licenseEndDate"
														rules={
															{
																required: true,
															}
														}
														render={({ field: { onChange } }) => (
															<InputDate
																errors={(!!form.formState.errors.licenseEndDate && form.formState.dirtyFields.licenseEndDate)}
																defaultValue={form.getValues('licenseEndDate') ? form.getValues('licenseEndDate') : ''}
																minDate={new Date(2000, 0)}
																onSelectDate={(date) => { onChange(date.toDateString()); form.trigger('licenseEndDate') }}
																position="top"
															>
																Fin vigencia
															</InputDate>
														)}
													/>
												</div>
											</>
										}

									</form>
								</div>

								<div className="claimRegister__button-wrapper">
									<Button type="button"
										disabled={states.isUpdatingForm.value}
										loading={states.isUpdatingForm.value}
										onClick={() => sendCurrentAdjusterInfo().then(
											() => {
												personalDataForwardNavigation(ClaimPersonManagementFacade.GENERAL_NAVIGATION_PARAMS.LICENSE_DATA)
											}
										)}
									>
										{ registerInfo && !registerInfo.personType.isThirdPartyDriver() ? 'Completar' : 'Siguiente' }
									</Button>
								</div>
							</div>
						</ClaimContent>
					}

					{ 
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.INSURANCE_DATA && 
						<ClaimContent
							title="Información general"
							subtitle="Seguro"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => {
									personalDataBackwardNavigation(ClaimPersonManagementFacade.GENERAL_NAVIGATION_PARAMS.INSURANCE);
								}}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonManagement__content">

								<div className="claimPersonManagement__content__wrapper">
									<div className="claimPersonManagement__content__wrapper__title">
										<span></span>
										<Text type="subtitle" className="claimPersonManagement__content__wrapper__title__text">Estatus de seguro</Text>
									</div>

									<form className="claimPersonManagement__content__wrapper__form claimPersonManagement__content__wrapper__form--shortMargin">
										<Text type="paragraph" align="center" className="claimPersonManagement__content__wrapper__title__text">¿El tercero conductor cuenta con seguro?</Text>
										<div className="claimPersonManagement__content__wrapper__form__field claimPersonManagement__content__wrapper__form__field--center">
											<Controller
												control={form.control}
												name="hasInsurance"
												rules={{
													required: false
												}}
												render={({ field: { onChange } }) => (
													<>
														<Radio
															defaultChecked={form.getValues('hasInsurance') === true}
															onChange={() => { states.setShowInsuranceData(true); form.setValue('hasInsurance', true); onChange(true) }}
															onClick={() => states.setShouldDeletePolicyData(false)}
															name="hasInsurance"
															//onClick={() => { states.setShowInsuranceData(true); form.setValue('hasInsurance', true); }}
														>
															Sí, tiene
														</Radio>
														<Radio
															defaultChecked={form.getValues('hasInsurance') === false}
															onChange={() => { onChange(false); states.setShowInsuranceData(false); form.setValue('hasInsurance', false) }}
															//onClick={() => states.deletePolicyData()}
															onClick={() => states.setShouldDeletePolicyData(true)}
															name="hasInsurance"
															//onClick={() => { states.onDeletePolicyDataConfirm(); form.setValue('hasInsurance', false); }}

														>
															No
														</Radio>
													</>
												)}
											/>
										</div>
										<Text className="claimPersonManagement__content__wrapper__warningText" type="label" align="center">Al dar a no, se borrarán todos los datos de la póliza.</Text>
									</form>

									{
										states.showInsuranceData &&
										<>			

											<div className="claimPersonManagement__content__wrapper__title">
												<span></span>
												<Text type="subtitle" className="claimPersonManagement__content__wrapper__title__text">Empresa de seguro</Text>
											</div>

											<form className="claimPersonManagement__content__wrapper__form claimPersonManagement__content__wrapper__form--shortMargin">
												<div className="claimPersonManagement__content__wrapper__form__field">
													<Dropdown
														placeholder="Selecciona el estado de emisión"
														// defaultValue={registerInfo?.insuranceCompany ? { id: registerInfo?.insuranceCompany?.id || '', value: registerInfo?.insuranceCompany.name } : undefined}
														defaultValue={states.selectedInsuranceCarrier ? { id: states.selectedInsuranceCarrier.id || '', value: states.selectedInsuranceCarrier.name } : undefined}
														items={list.insuranceCarriersList ? list.insuranceCarriersList.map(i => ({ id: i.id, value: i.name })) : []}
														onChange={(item) => states.selectInsuranceCarrierById(item.id as string)}
													>
														Aseguradora
													</Dropdown>
												</div>
											</form>

											<div className="claimPersonManagement__content__wrapper__title">
												<span></span>
												<Text type="subtitle" className="claimPersonManagement__content__wrapper__title__text">Detalles de la póliza</Text>
											</div>

											<form className="claimPersonManagement__content__wrapper__form">
												<div className="claimPersonManagement__content__wrapper__form__field">
													<Controller
														control={form.control}
														name="policyNumber"
														rules={
															{
																required: true
															}
														}
														render={({ field: { onChange } }) => (
															<InputLabel
																errors={(!!form.formState.errors.policyNumber && form.formState.dirtyFields.policyNumber)}
																defaultValue={form.getValues('policyNumber')}
																onChange={(e) => onChange(e.target.value)}
															>
																Nro de póliza
															</InputLabel>
														)}
													/>
												</div>

												<div className="claimPersonManagement__content__wrapper__form__field">
													<Controller
														control={form.control}
														name="itemNumber"
														rules={
															{
																required: true
															}
														}
														render={({ field: { onChange } }) => (
															<InputLabel
																errors={(!!form.formState.errors.itemNumber && form.formState.dirtyFields.itemNumber)}
																defaultValue={form.getValues('itemNumber')}
																onChange={(e) => onChange(e.target.value)}
															>
																Nro de inciso
															</InputLabel>
														)}
													/>
												</div>

												<div className="claimPersonManagement__content__wrapper__title">
													<span></span>
													<Text type="subtitle" className="claimPersonManagement__content__wrapper__title__text">Detalles del siniestro</Text>
												</div>

												<div className="claimPersonManagement__content__wrapper__form__field">
													<Controller
														control={form.control}
														name="claimNumber"
														rules={
															{
																required: true
															}
														}
														render={({ field: { onChange } }) => (
															<InputLabel
																errors={(!!form.formState.errors.claimNumber && form.formState.dirtyFields.claimNumber)}
																defaultValue={form.getValues('claimNumber')}
																onChange={(e) => onChange(e.target.value)}
															>
																Nro de siniestro
															</InputLabel>
														)}
													/>
												</div>

												<div className="claimPersonManagement__content__wrapper__form__field">
													<Controller
														control={form.control}
														name="duaNumber"
														render={({ field: { onChange } }) => (
															<InputLabel
																errors={(!!form.formState.errors.claimNumber && form.formState.dirtyFields.claimNumber)}
																defaultValue={form.getValues('duaNumber')}
																onChange={(e) => onChange(e.target.value)}
															>
																Nro de DUA
															</InputLabel>
														)}
													/>
												</div>

												<div className="claimPersonManagement__content__wrapper__form__field">
													<Controller
														control={form.control}
														name="adjuster"
														render={({ field: { onChange } }) => (
															<InputLabel
																errors={(!!form.formState.errors.claimNumber && form.formState.dirtyFields.claimNumber)}
																defaultValue={form.getValues('adjuster')}
																onChange={(e) => onChange(e.target.value)}
															>
																Ajustador
															</InputLabel>
														)}
													/>
												</div>
											</form>
										</>
									}
								</div>

								<div className="claimRegister__button-wrapper">
									<Button 
										type="button"
										loading={states.isUpdatingForm.value}
										disabled={(form.getValues('hasInsurance') === true && !form.formState.isValid) || states.isUpdatingForm.value}
										onClick={() => sendCurrentAdjusterInfo().then(() => 
											personalDataForwardNavigation(ClaimPersonManagementFacade.GENERAL_NAVIGATION_PARAMS.INSURANCE)
										)}
									>
										Completar
									</Button>
								</div>

								<DeleteItemModal 
									type="policy" 
									show={states.showPolicyDeletionModal}
									onConfirmDelete={states.deletePolicyData}
									onCancel={() => {states.setShowPolicyDeletionModal(false); form.setValue('hasInsurance', true); }}
								/>
							</div>
						</ClaimContent>
					}	
        </>
      }
    </div>
  );
};

export default ClaimPersonGeneralPage;