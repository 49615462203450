import { Invoice } from "src/classes/invoice/Invoice";
import { InvoicePremium } from "src/classes/invoice/InvoicePremium";
import { InvoiceStatus } from "src/classes/invoice/InvoiceStatus";
import { IInvoiceDetailsData } from "../interfaces/IInvoiceDetailsData";
import { IValidateInvoiceData } from "../interfaces/IValidateInvoceData";
import { IInvoiceRequest } from "../interfaces/IInvoiceRequest";
import { Bill } from "src/classes/invoice/Bill";
import { IInvoicePersonInfoData } from "../interfaces/IInvoicePersonInfoData";
import { ITaxRegimenData } from "../interfaces/ITaxRegimenData";
import { TaxRegime } from "src/classes/invoice/TaxRegime";
import { ICreateInvoiceRequest } from "../interfaces/ICreateInvoiceRequest";
import { Util } from "src/utils/Util";
import { RegimeUse } from "src/classes/invoice/RegimeUse";
import { InvoiceFile } from "src/classes/invoice/InvoiceFile";
import { IInvoiceFileData } from "../interfaces/IInvoiceFileData";

export namespace BillAdapter {

  /**
   * 
   * @param productId 
   * @param token 
   * @returns 
   */
  export const invoiceRequestAdapter = (productId: string, token: string): IInvoiceRequest => {
    const request: IInvoiceRequest = {
      productId: productId,
      token: token,
    }

    return request;
  }

  /**
   * 
   * @param data 
   */
  export const validateInvoiceDataAdapter = (data: IValidateInvoiceData): InvoiceStatus => {
    const status: InvoiceStatus = new InvoiceStatus(new Date(data.response.expire_date), data.response.status);

    if (data.response.invoices) {
      for (const invoiceData of data.response.invoices) {
        let name: string = '';
        let isComplement: boolean = false;
        if (invoiceData.operation_type === 'complement') {
          name = `Pago #${invoiceData.payment_number}`;
          isComplement = true;
        } else if (invoiceData.operation_type === 'general') {
          name = 'Factura PUE';
        } else if (invoiceData.operation_type === 'invoice') {
          name = 'Factura PPD';
        }

        let invoiceFile: InvoiceFile = new InvoiceFile(name, invoiceData.facturama_id);
        invoiceFile.isComplement = isComplement;
        if (invoiceData.payment_number) {
          invoiceFile.fileNumber = invoiceData.payment_number;
        }

        status.invoicesFile.push(invoiceFile);
      }
    }

    return status;
  }

  /**
   * 
   * @param data 
   * @param invoiceFile 
   */
  export const invoiceFileDataAdapter = (data: IInvoiceFileData, invoiceFile: InvoiceFile): void => {
    for (const file of data.data.files) {
      if (file.content_type === 'pdf') {
        invoiceFile.pdfBase64 = `data:application/pdf;base64,${file.content}`;
      } else if (file.content_type === 'xml'){
        invoiceFile.xmlBase64 = `data:application/xml;base64,${file.content}`;
      }
    }
  }

  /**
   * 
   * @param data 
   */
  export const validateInvoiceErrorAdapter = (): InvoiceStatus => {
    const status: InvoiceStatus = new InvoiceStatus(new Date(), Util.STATUS_CODE.POLICY_INVOICE_STATUS.EMPTY);
    return status;
  }

  /**
   * 
   * @param data 
   */
  export const validateInvoiceFilesErrorAdapter = (): InvoiceStatus => {
    const status: InvoiceStatus = new InvoiceStatus(new Date(), Util.STATUS_CODE.POLICY_INVOICE_STATUS.FILE_ERROR);
    return status;
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const invoiceDetailsAdapter = (data: IInvoiceDetailsData): Invoice => {
    let invoice: Invoice = new Invoice();
    invoice.netAmount = parseFloat(data.response.total);
    invoice.taxes = parseFloat(data.response.total_taxes);

    for (const item of data.response.policies) {
      let premium: InvoicePremium = new InvoicePremium();
      premium.description = item.description;
      premium.policyNumber = item.number;
      premium.subTotal = parseFloat(item.subtotal);
      premium.taxes = parseFloat(item.taxes);

      invoice.premiumList.push(premium);
    }

    return invoice;
  }

  /**
   * 
   * @param data 
   * @returns 
   */
  export const invoicePersonInfoAdapter = (data: IInvoicePersonInfoData): Bill[] => {
    let billList: Bill[] = [];

    for(const dataItem of data.response.tax_persons) {
      let bill: Bill = new Bill();
      bill.personId = dataItem.person_id;
      bill.id = dataItem.id;
      bill.personName = dataItem.name;
      bill.personEmail = dataItem.email;
      bill.rfc = dataItem.rfc;
      bill.postalCode = dataItem.address.postal_code;

      let regime: TaxRegime = new TaxRegime( dataItem.tax_regime, '', false, false);
      bill.taxRegime = regime;

      billList.push(bill);
    }


    return billList;
  }

  /**
   * 
   * @param _data 
   * @returns 
   */
  export const taxRegimesListDataAdapter = (data: ITaxRegimenData): TaxRegime[] => {
    let _taxRegimes: TaxRegime[] = [];

    for (const _itemData of data.response.tax_regime_result) {
      let _taxRegime = new TaxRegime(parseInt(_itemData.value), _itemData.name, _itemData.moral === 'true', _itemData.natural === 'true');

      try {
        for (const useData of _itemData.uses) {
          const _regimeUse: RegimeUse = new RegimeUse(useData.id, useData.value, useData.name);
          _taxRegime.uses.push(_regimeUse);
        }
      } catch (e) {
        console.warn(_itemData);
      }

      _taxRegimes.push(_taxRegime);
    };
    return _taxRegimes;
  }

  /**
   * 
   * @param bill 
   * @param productId 
   * @returns 
   */
  export const createInvoiceRequestAdapter = (bill: Bill, productId: string): ICreateInvoiceRequest => {
    let request: ICreateInvoiceRequest = {
      product_id: productId,
      tax_person: {
        tax_person_type: bill.taxRegime?.isIndependant? 'fisica' : 'moral',
        tax_regime: bill.taxRegime?.id as number,
        email: bill.personEmail,
        name: bill.personName,
        rfc: bill.rfc,
        tax_use: bill.regimeUse?.code,
        selected: bill.saveData? 'true' : 'false',
        address: {
          postal_code: bill.postalCode
        }
      }
    }

    if (bill.id) {
      request.tax_person.id = bill.id;
      request.tax_person.person_id = bill.personId;
    }

    return request;
  }
}