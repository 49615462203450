import { CLAIM_ADJUSTER_PERSON_EDIT_PARAMS } from "src/routes/paths/claim-register.paths";
import { Button, Dropdown, InputLabel, Text } from "src/components";
import { Util } from "src/utils/Util";
import { Controller } from "react-hook-form";
import { ClaimPersonManagementFacade } from "src/modules/claim-register/controller/facades/claim-person-management.facade";
import { ClaimPicturesFacade } from "src/modules/claim-register/controller/facades/claim-person-pictures.facade";
import ClaimContent from "../../components/ClaimContent/ClaimContent";
import ClaimPicture from "../../components/ClaimPicture/ClaimPicture";

import './ClaimPersonVehicle.scss';
import { IconFactory } from "src/factory/icon.factory";

/**
 *
 */
const ClaimPersonVehiclePage = (): JSX.Element => {
	
	/**
	 * 
	*/
	const { vehicleNavigation } = ClaimPersonManagementFacade.useClaimPersonManagement();
  const { form, urlParam, states, list, registerInfo, adjusterInfo, contentRef, sendCurrentAdjusterInfo } = ClaimPersonManagementFacade.useClaimPersonManagementForm();
	const vehiclePictures = ClaimPicturesFacade.useClaimPictures(4, registerInfo?.vehiclePictures);
  const vinPictures = ClaimPicturesFacade.useClaimPictures(1, registerInfo?.vinPictures);

  return (
    <div className="claimPersonVehicle">

			{
        urlParam.editPage &&
        <>

					{
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA &&
						<ClaimContent
							title="Información de vehículo"
							subtitle="Datos generales"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => vehicleNavigation(ClaimPersonManagementFacade.VEHICLE_NAVIGATION_PARAMS.EDIT_CLAIM_REGISTER, true)}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonManagement__content" ref={contentRef}>
								<div className="claimPersonManagement__content__wrapper">
									<form className="claimPersonManagement__content__wrapper__form">
										<Dropdown
											cancelSearch
											items={list.vehicleTypes ? list.vehicleTypes.map(l => ({ id: l.id, value: l.name })) : []}
											onChange={(item) => states.selectVehicleTypeById(item.id as string)}
											disabled={registerInfo && registerInfo.personType.isNA()}
											defaultValue={states.selectedVehicleType ? { id: states.selectedVehicleType.id, value: states.selectedVehicleType.name } : undefined}
											placeholder="Selecciona el tipo"
										>
											Tipo <span>*</span>
										</Dropdown>

										<div className="claimPersonManagement__content__wrapper__form__field">
											<Controller
												control={form.control}
												name="vehicleBrand"
												rules={
													{
														pattern: Util.PATTERN.ALPHANUMERIC,
														maxLength: 100
													}
												}
												render={({ field: { onChange } }) => (
													<InputLabel
														placeholder="Escribe aquí la marca"
														maxLength={100}
														disabled={registerInfo && registerInfo.personType.isNA()}
														errors={!!form.formState.errors.vehicleBrand && form.formState.dirtyFields.vehicleBrand}
														defaultValue={form.getValues('vehicleBrand')}
														onChange={(e) => onChange(e.target.value)}
													>
														Marca
													</InputLabel>
												)}
											/>
										</div>

										<div className="claimPersonManagement__content__wrapper__form__field">
											<Controller
												control={form.control}
												name="vehicleModel"
												rules={
													{
														pattern: Util.PATTERN.VEHICLE_MODEL,
														maxLength: 100
													}
												}
												render={({ field: { onChange } }) => (
													<InputLabel
														placeholder="Escribe aquí el modelo"
														maxLength={100}
														disabled={registerInfo && registerInfo.personType.isNA()}
														errors={!!form.formState.errors.vehicleModel && form.formState.dirtyFields.vehicleModel}
														defaultValue={form.getValues('vehicleModel')}
														onChange={(e) => onChange(e.target.value)}
													>
														Modelo
													</InputLabel>
												)}
											/>
										</div>
										<div className="claimPersonManagement__content__wrapper__form__field">
											<Controller
												control={form.control}
												name="vehicleYear"
												rules={
													{
														pattern: Util.PATTERN.NUMBERS,
														maxLength: 4
													}
												}
												render={({ field: { onChange } }) => (
													<InputLabel
														number
														placeholder="Escribe aquí el año"
														maxLength={4}
														disabled={registerInfo && registerInfo.personType.isNA()}
														errors={!!form.formState.errors.vehicleYear && form.formState.dirtyFields.vehicleYear}
														defaultValue={+form.getValues('vehicleYear') > 0 ? form.getValues('vehicleYear') : undefined}
														onChange={(e) => onChange(e.target.value)}
													>
														A&ntilde;o
													</InputLabel>
												)}
											/>
										</div>
										<div className="claimPersonManagement__content__wrapper__form__field">
											<Controller
												control={form.control}
												name="plates"
												rules={
													{
														pattern: Util.PATTERN.VEHICLE_PLATE,
														maxLength: 20
													}
												}
												render={({ field: { onChange } }) => (
													<InputLabel
														placeholder="Escribe aquí las placas"
														maxLength={7}
														errors={!!form.formState.errors.plates && form.formState.dirtyFields.plates}
														defaultValue={form.getValues('plates')}
														onChange={(e) => onChange(e.target.value)}
													>
														Placas
													</InputLabel>
												)}
											/>
										</div>

										<div className="claimPersonManagement__content__wrapper__form__field">
											<Dropdown
												cancelSearch
												items={list.colors ? list.colors.map(l => ({ id: l.id, value: l.name })) : []}
												onChange={(item) => states.selectColorById(item.id as string)}
												defaultValue={states.selectedColor ? { id: states.selectedColor.id, value: states.selectedColor.name } : undefined}
												placeholder="Selecciona el color del vehículo"
												openOverflow
												onDropdownOpen={() => {
													if (contentRef && contentRef.current)
														contentRef.current.scrollIntoView({ behavior: 'smooth' })
												}}
											>
												Color
											</Dropdown>
										</div>
									</form>
								</div>

								<div className="claimRegister__button-wrapper">
									<Button 
										type="button"
										disabled={!states.selectedVehicleType || states.isUpdatingForm.value}
                    loading={states.isUpdatingForm.value}
										onClick={() => sendCurrentAdjusterInfo().then(() => {
											adjusterInfo && !adjusterInfo.claimType.isClaimWindscreen() && !adjusterInfo.claimType.isClaimRobbery() ?
												vehicleNavigation(ClaimPersonManagementFacade.VEHICLE_NAVIGATION_PARAMS.PICTURES)
											:
												(adjusterInfo && !adjusterInfo.claimType.isClaimRobbery() ? vehicleNavigation(ClaimPersonManagementFacade.VEHICLE_NAVIGATION_PARAMS.VIN) : vehicleNavigation(ClaimPersonManagementFacade.VEHICLE_NAVIGATION_PARAMS.VIN))
										})}
									>
										{ adjusterInfo && !adjusterInfo.claimType.isClaimRobbery() ? 'Siguiente' : 'Completar' }
									</Button>
								</div>
							</div>
						</ClaimContent>
					}

					{
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.VEHICLE_PICTURES && 
						<ClaimContent
							title="Información de vehículo"
							subtitle="Fotografías"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => vehicleNavigation(ClaimPersonManagementFacade.VEHICLE_NAVIGATION_PARAMS.DATA, true)}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonManagement__content">
								<Text type="paragraph" align="center" block bold>Compártenos una foto de cada uno <br/> de los lados del vehículo</Text>

								<div className="claimPersonManagement__content__wrapper claimPersonManagement__content__wrapper--wrap">
									<div className="claimPersonManagement__content__wrapper__field">
										<ClaimPicture
											defaultPicture={vehiclePictures.pictures[0]}
                      onSelected={(picture) => vehiclePictures.setPictureValue(0, picture)}
										/>
										{/* <Text type="paragraph" align="center" block>Foto #1</Text> */}
									</div>
									<div className="claimPersonManagement__content__wrapper__field">
										<ClaimPicture
											defaultPicture={vehiclePictures.pictures[1]}
                      onSelected={(picture) => vehiclePictures.setPictureValue(1, picture)}
										/>
										{/* <Text type="paragraph" align="center" block>Foto #2</Text> */}
									</div>
									<div className="claimPersonManagement__content__wrapper__field">
										<ClaimPicture
											defaultPicture={vehiclePictures.pictures[2]}
                      onSelected={(picture) => vehiclePictures.setPictureValue(2, picture)}
										/>
										{/* <Text type="paragraph" align="center" block>Foto #3</Text> */}
									</div>
									<div className="claimPersonManagement__content__wrapper__field">
										<ClaimPicture
											defaultPicture={vehiclePictures.pictures[3]}
                      onSelected={(picture) => vehiclePictures.setPictureValue(3, picture)}
										/>
										{/* <Text type="paragraph" align="center" block>Foto #4</Text> */}
									</div>
								</div>

								<div className="claimRegister__button-wrapper">
									<Button 
										type="button"
                    disabled={states.isUpdatingForm.value}
                    loading={states.isUpdatingForm.value}
										onClick={() => sendCurrentAdjusterInfo(false, vehiclePictures.pictures, 'vehicle').then(() => vehicleNavigation(ClaimPersonManagementFacade.VEHICLE_NAVIGATION_PARAMS.VIN))}
									>
										Siguiente
									</Button>
								</div>
							</div>
						</ClaimContent>
					}

					{
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.VEHICLE_VIN &&
						<ClaimContent
							title="Información de vehículo"
							subtitle="Número de serie"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => {
									adjusterInfo && !adjusterInfo.claimType.isClaimWindscreen() ?
										vehicleNavigation(ClaimPersonManagementFacade.VEHICLE_NAVIGATION_PARAMS.PICTURES)
									:
										vehicleNavigation(ClaimPersonManagementFacade.VEHICLE_NAVIGATION_PARAMS.DATA)
								}}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonManagement__content">
								<div className="claimPersonManagement__content__wrapper">

								</div>
								<form className="claimPersonManagement__content__wrapper__form">
									<div className="claimPersonManagement__content__wrapper__form__field">
										<ClaimPicture
											defaultPicture={vinPictures.pictures[0]}
                      onSelected={(picture) => vinPictures.setPictureValue(0, picture)}
										/>
										<Text type="paragraph" align="center" block bold>N&uacute;mero de serie del veh&iacute;culo</Text>
									</div>
									<div className="claimPersonManagement__content__wrapper__form__field">
										<Controller
											control={form.control}
											name="vin"
											rules={
												{
													pattern: Util.PATTERN.VEHICLE_VIN,
													maxLength: 17
												}
											}
											render={({ field: { onChange } }) => (
												<InputLabel
													placeholder="Escribe aquí el número de serie"
													maxLength={17}
													errors={!!form.formState.errors.vin && form.formState.dirtyFields.vin}
													disabled={registerInfo?.personType.isNA()}
													defaultValue={form.getValues('vin')}
													onChange={(e) => onChange(e.target.value)}
												>
													N&uacute;mero de serie
												</InputLabel>
											)}
										/>
									</div>

									<div className="claimPersonManagement__content__wrapper__form__info">
										<div className="claimPersonManagement__content__wrapper__form__info__right">
											<Text type="label">
												<b>¿No sabes d&oacute;nde está el n&uacute;mero de serie?</b> Lo puedes encontrar en el tablero, en la puerta del conductor, o en la pared de fuego.
											</Text>
										</div>
									</div>

								</form>

								<div className="claimRegister__button-wrapper">
									<Button 
										type="button" 
                    disabled={states.isUpdatingForm.value}
                    loading={states.isUpdatingForm.value}
										onClick={() => sendCurrentAdjusterInfo(false, vinPictures.pictures, 'vin').then(() => vehicleNavigation(ClaimPersonManagementFacade.VEHICLE_NAVIGATION_PARAMS.EDIT_CLAIM_REGISTER))}
									>
										Completar
									</Button>
								</div>
							</div>
						</ClaimContent>
					}
        </>
      }
    </div>
  );
};

export default ClaimPersonVehiclePage;