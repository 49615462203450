import { CLAIM_ADJUSTER_PERSON_EDIT_PARAMS } from "src/routes/paths/claim-register.paths";
import { Button, Checkbox, Dropdown, InputArea, InputLabel, InputPhone, Text } from "src/components";
import { Util } from "src/utils/Util";
import ClaimContent from "../../components/ClaimContent/ClaimContent";
import { IconFactory } from "src/factory/icon.factory";
import { ClaimPersonManagementFacade } from "src/modules/claim-register/controller/facades/claim-person-management.facade";
import { Controller } from "react-hook-form";
import DeleteItemModal from "../../components/DeleteItemModal/DeleteItemModal";
import { ClaimPicturesFacade } from "src/modules/claim-register/controller/facades/claim-person-pictures.facade";

import ClaimPicture from "../../components/ClaimPicture/ClaimPicture";
import { useEffect } from "react";
import './ClaimPersonService.scss';

/**
 *
 */
const ClaimPersonServicePage = (): JSX.Element => {
	
	/**
	 * 
	 */
	const { serviceNavigation } = ClaimPersonManagementFacade.useClaimPersonManagement();
  const { registerInfo, form, states, list, urlParam, hasOrderMessage, sendCurrentAdjusterInfo } = ClaimPersonManagementFacade.useClaimPersonManagementForm();
	const { shouldDisplayCategory, shouldDisplayStates, shouldDisplayProvider, shouldDisplayProviderType, shouldDisplayCoverage, shouldDisplayImport, shouldDisplayDeductible, shouldDisplayOperatorInputs } = ClaimPersonManagementFacade.useClaimPersonServiceValidations(states.selectedServiceType, states.selectedCategory);
	const servicePictures = ClaimPicturesFacade.useClaimPictures(undefined, states.serviceToEdit?.pictures);

	/**
	 * 
	 */
	useEffect(() => {
		if (states.serviceToEdit === undefined || urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LIST) {
			servicePictures.setDefaulPictures(['']);
      servicePictures.pictures = [];
    }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [states.serviceToEdit, urlParam.editPage])
	
  return (
    <div className="claimPersonService">

			{
        urlParam.editPage &&
        <>
					{
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.LIST &&
						<ClaimContent
							title="Gestión de servicios"
							subtitle="Añade, edita o elimina"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => serviceNavigation(ClaimPersonManagementFacade.SERVICE_NAVIGATION_PARAMS.EDIT_CLAIM_REGISTER, true)}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonService__content">
								<div className="claimPersonService__content__wrapper">
									{
										registerInfo && registerInfo.services.map(
											(service, i) => (											
												<div className="claimPersonService__content__wrapper__service" key={`service-${i}-${service.type?.id}`}>
													<span className="claimPersonService__content__wrapper__service__image" style={{ backgroundImage: `url(${Util.ASSET.CLAIMS_SERVICE_IMAGE})` }}></span>
													<div className="claimPersonService__content__wrapper__service__type">
														<Text type="label">Servicio de</Text>
														<Text type="paragraph" bold>{service.type?.name}</Text>
													</div>
													<div className="claimPersonService__content__wrapper__service__buttons">
														<button type="button" className="claimPersonService__content__wrapper__service__buttons__edit"
															onClick={() => { states.setServiceEdition(service, i); serviceNavigation(ClaimPersonManagementFacade.SERVICE_NAVIGATION_PARAMS.DATA, undefined, true) }}
														>
															{IconFactory.editIcon()}
														</button>
														{
															!service.isPrior &&
																<button type="button" className="claimPersonService__content__wrapper__service__buttons__remove"
																	onClick={() => { states.setDeleteItemIndex(i); states.setShowDeleteModal(true) }}
																>
																	{IconFactory.removeIcon()}
																</button>
														}
													</div>
												</div>
											)
										)
									}

									<div className="claimPersonService__content__wrapper__add-button">
										<button type="button"
											onClick={() => serviceNavigation(ClaimPersonManagementFacade.SERVICE_NAVIGATION_PARAMS.TYPE)}
										>
											{IconFactory.addIcon()}
											<Text type="paragraph" align="center" uppercase>
												A&ntilde;adir
												{registerInfo && registerInfo.services.length > 0 ? <>&nbsp;otro&nbsp;</> : <>&nbsp;</>}
												servicio
											</Text>
										</button>
									</div>
								</div>

								<div className="claimRegister__button-wrapper claimPersonService__buttonWrapper">
									<div className="claimPersonService__buttonWrapper__info">
										<div className="claimPersonService__buttonWrapper__info__image" style={ { backgroundImage: `url(${Util.ASSET.CLAIMS_INFO})` } }></div>
										<Text type="paragraph"><b>Nota:</b> Los Sipacs se deben meter desde el tercero, no desde el NA.</Text>
									</div>

									<Button 
										type="button"
										onClick={() => serviceNavigation(ClaimPersonManagementFacade.SERVICE_NAVIGATION_PARAMS.EDIT_CLAIM_REGISTER)}
									>
										Completar
									</Button>
								</div>

								<DeleteItemModal 
									type="service" 
									show={states.showDeleteModal}
									onConfirmDelete={states.removeService}
									onCancel={() => states.setShowDeleteModal(false)}
								/>
							</div>
						</ClaimContent>
					}

					{
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.SERVICE_TYPE && 
						<ClaimContent
							title="Añadir servicio"
							subtitle="Tipo y categoría"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => { states.setServiceEdition(); serviceNavigation(ClaimPersonManagementFacade.SERVICE_NAVIGATION_PARAMS.LIST, true)}}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonService__content">
								<div className="claimPersonService__content__wrapper">
									<div className="claimPersonService__content__wrapper__field">
										<Dropdown
											cancelSearch
											items={list.serviceTypesList ? list.serviceTypesList.map(s => ({ id: s.id, value: s.name })) : []}
											onChange={(item) => states.selectServiceTypeById(item.id as string)}
											defaultValue={states.serviceToEdit?.type ? { id: states.serviceToEdit.type.id, value: states.serviceToEdit.type.name } : undefined}
											placeholder="Selecciona el tipo"
										>
											Tipo de servicio
										</Dropdown>
									</div>

									{
										states.selectedServiceType && shouldDisplayCategory(states.selectedServiceType) &&
											<div className="claimPersonService__content__wrapper__field">
												<Dropdown
													cancelSearch
													items={list.categoriesList ? list.categoriesList.map(c => ({ id: c.id, value: c.name })) : []}
													onChange={(item) => states.selectCategoryById(item.id as string)}
													defaultValue={states.serviceToEdit?.category ? { id: states.serviceToEdit.category.id, value: states.serviceToEdit.category.name } : undefined}
													placeholder="Selecciona la categoría"
												>
													Categoría de servicio
												</Dropdown>
											</div>
									}
								</div>

								<div className="claimRegister__button-wrapper">
									<Button 
										type="button"
										disabled={!states.selectedServiceType || (states.selectedServiceType && shouldDisplayCategory(states.selectedServiceType) && !states.selectedCategory)}
										onClick={() => serviceNavigation(ClaimPersonManagementFacade.SERVICE_NAVIGATION_PARAMS.DATA)}
									>
										Siguiente
									</Button>
								</div>
							</div>
						</ClaimContent>
					}

					{
						urlParam.editPage === CLAIM_ADJUSTER_PERSON_EDIT_PARAMS.DATA &&
						<ClaimContent
							title={ (states.serviceToEdit ? 'Editar' : 'Añadir') + ' servicio'}
							subtitle="Información general"
							backButton={
                <button type="button" className="claimRegister__button-back" onClick={() => serviceNavigation(ClaimPersonManagementFacade.SERVICE_NAVIGATION_PARAMS.TYPE, true, false)}>
                  {IconFactory.leftArrowIcon()}
                </button>
              }
						>
							<div className="claimPersonService__content">
								<div className="claimPersonService__content__wrapper">
									{
										states.selectedServiceType && shouldDisplayStates(states.selectedServiceType) &&
											<div className="claimPersonService__content__wrapper__field">
												<Dropdown
													defaultValue={states.serviceToEdit?.provider?.address ? { id: states.serviceToEdit?.provider?.address.name!, value: states.serviceToEdit?.provider?.address.name! } : undefined}
													placeholder="Selecciona el estado del proveedor"
													onChange={(item) => states.selectProviderStateById(item.value)}
													items={list.countryStates ? list.countryStates.map(s => ({ id: s.state, value: s.state })) : []}
												>
													Estado
												</Dropdown>
											</div>
									}

									{
										states.selectedServiceType && shouldDisplayProviderType(states.selectedServiceType) &&
											<div className="claimPersonService__content__wrapper__field">
												<Dropdown
													//defaultValue={states.serviceToEdit?.provider?.id ? { id: states.serviceToEdit.provider.id, value: states.serviceToEdit.provider.name } : undefined}
													placeholder="Selecciona el tipo de CDR"
													onChange={(item) => states.selectProviderTypeById(item.id as number)}
													items={ClaimPersonManagementFacade.PROVIDER_TYPES ? ClaimPersonManagementFacade.PROVIDER_TYPES.map(t => ({ id: t.id, value: t.value })) : []}
												>
													Tipo de CDR
												</Dropdown>
											</div>
									}

									{
										states.selectedServiceType && shouldDisplayProvider(states.selectedServiceType, states.selectedCategory) &&
											<div className="claimPersonService__content__wrapper__field">
												<Dropdown
													
													items={list.providersList ? list.providersList.map(p => ({ id: p.id, value: p.name })) : []}
													onChange={(item) => states.selectProviderById(item.id as string)}
													defaultValue={states.serviceToEdit?.provider?.id ? { id: states.serviceToEdit.provider.id!, value: states.serviceToEdit.provider.name } : undefined}
													placeholder="Selecciona el proveedor"
												>
													Proveedor
												</Dropdown>
											</div>
									}

									{
										states.selectedProvider && states.selectedProvider.address &&
										<div className="claimPersonService__content__wrapper__providerAddress">
											<Text type="paragraph" bold align="center">Dirección del proveedor</Text>
											<Text className="claimPersonService__content__wrapper__providerAddress__value" type="paragraph" align="center">
                        {states.selectedProvider.address.street}, {states.selectedProvider.address.municipality} {states.selectedProvider.address.postalCode}. {states.selectedProvider.address.state}
                      </Text>
										</div>
									}

									{
										states.selectedServiceType && shouldDisplayCoverage(states.selectedServiceType) &&
											<div className="claimPersonService__content__wrapper__field">
												<Dropdown
													cancelSearch
													disabled={states.serviceToEdit && states.serviceToEdit.isPrior && !!states.serviceToEdit.coverage?.id}
													items={list.coveragesList ? list.coveragesList.map(s => ({ id: s.id, value: s.name })) : []}
													onChange={(item) => states.selectCoverageById(item.id as string)}
													defaultValue={states.serviceToEdit?.coverage?.id ? { id: states.serviceToEdit.coverage.id, value: states.serviceToEdit.coverage.name } : undefined}
													placeholder="Selecciona la cobertura"
												>
													Cobertura
												</Dropdown>
											</div>
									}

									{ 
										states.selectedServiceType && shouldDisplayImport(states.selectedServiceType, states.selectedCategory) &&
											<div className="claimPersonService__content__wrapper__field">
												<Controller
													control={form.control}
													name="serviceAmount"
													rules={
														{
															required: true,
														}
													}
													render={({ field: { onChange } }) => (
														<InputLabel
															errors={(!!form.formState.errors.serviceAmount && form.formState.dirtyFields.serviceAmount)}
															defaultValue={states.serviceToEdit?.amount}
															onChange={(e) => onChange(e.target.value)}
															type="number"
															amount
														>
															Reserva
														</InputLabel>
													)}
												/>
											</div>
									}

									{
										states.selectedServiceType && registerInfo && registerInfo.personType.isNA() && shouldDisplayDeductible(states.selectedServiceType, states.selectedCategory) &&
											<div className="claimPersonService__content__wrapper__field">
												<Controller
													control={form.control}
													name="hasDeductible"
													rules={
														{
															required: true,
														}
													}
													render={({ field: { onChange } }) => (
														<Checkbox
															defaultChecked={form.getValues('hasDeductible')}
															onChange={(e) => onChange(e.target.checked)}
														>
															Deducible
														</Checkbox>
													)}
												/>
											</div>
									}

									{
										states.selectedServiceType && shouldDisplayOperatorInputs(states.selectedServiceType) &&
											<>
												<div className="claimPersonService__content__wrapper__field">
													<Controller
														control={form.control}
														name="serviceSheetNumber"
														rules={
															{
																required: true,
															}
														}
														render={({ field: { onChange } }) => (
															<InputLabel
																errors={(!!form.formState.errors.serviceSheetNumber && form.formState.dirtyFields.serviceSheetNumber)}
																defaultValue={states.serviceToEdit?.itemNumber}
																onChange={(e) => onChange(e.target.value)}
															>
																Nro. de folio
															</InputLabel>
														)}
													/>
												</div>

												<div className="claimPersonService__content__wrapper__form__field">
													<Controller
														control={form.control}
														name="operatorName"
														rules={
															{
																required: true,
																pattern: Util.PATTERN.NAMES
															}
														}
														render={({ field: { onChange } }) => (
															<InputLabel
																errors={!!form.formState.errors.operatorName && form.formState.dirtyFields.operatorName}
																maxLength={200}
																defaultValue={states.serviceToEdit?.operatorName}
																onChange={(e) => onChange(e.target.value)}
															>
																Nombre del operador
															</InputLabel>
														)}
													/>
												</div>

												<div className="claimPersonService__content__wrapper__form__field">
													<Controller
														control={form.control}
														name="operatorPhone"
														rules={
															{
																required: true,
																pattern: Util.PATTERN.NUMBERS,
																maxLength: 10,
																minLength: 10
															}
														}
														render={({ field: { onChange } }) => (
															<InputPhone
																lockCountryCode
																placeholder="Teléfono"
																errors={!!form.formState.errors.operatorPhone && form.formState.dirtyFields.operatorPhone}
																defaultValue={states.serviceToEdit?.operatorPhone?.number}
																onCountryCodeSelected={(code) => form.setValue('operatorCountryCode', code)}
																onChange={(e) => onChange(e.target.value)}
															>
																Tel&eacute;fono del operador
															</InputPhone>
														)}
													/>
												</div>
											</>
									}

									<div className="claimPersonService__content__wrapper__field">
										<Controller
											control={form.control}
											name="serviceDescription"
											rules={
												{
													required: true,
												}
											}
											render={({ field: { onChange } }) => (
												<InputArea 
													maxLength={5000} 
													rows={8}
													errors={(!!form.formState.errors.serviceDescription && form.formState.dirtyFields.serviceDescription)}
													defaultValue={states.serviceToEdit?.description}
													onChange={(e) => onChange(e.target.value)}
													name="serviceTextarea"
													placeholder="Solo en caso de que haya información extra relativa al servicio que consideres necesaria"
												>
													Descripción
												</InputArea>
											)}
										/>
									</div>

									<div className="claimPersonService__pictures">
										<Text className="claimPersonService__pictures__text" type="paragraph" align="center">En caso de que haya fotos relativas al servicio que consideres necesarias, súbelas a continuación.</Text>

										{
											servicePictures.pictures.map(
												(src, index) => (
													<div key={`service-image-${index}`} className="claimPerson__pictures__item">
														<ClaimPicture 
															cancelEdit
															onSelected={(picture) => servicePictures.addPicture(picture, index)}
															defaultPicture={src} 
														/>
														<Text type="label" align="center" block bold>Foto #{index + 1}</Text>
													</div>
												)
											)
										}

										<div className="claimPerson__content__form__add-button">
											<button type="button" onClick={() => servicePictures.newPicture()}>
												{IconFactory.addIcon()}
											</button>
											<Text type="label" bold align="center">A&ntilde;adir otra imagen</Text>
										</div>
									</div>
								</div>

								<div className="claimRegister__button-wrapper">
									{
										hasOrderMessage() &&
											<div className="claimPersonService__buttonWrapper__info">
												<div className="claimPersonService__buttonWrapper__info__image" style={ { backgroundImage: `url(${Util.ASSET.CLAIMS_INFO})` } }></div>
												<Text type="paragraph"><b>Nota:</b> La orden se enviará al whatsapp del ajustador al firmar y concluir el formulario.</Text>
											</div>
									}

									<Button 
                    type="button"
                    disabled={states.isUpdatingForm.value}
                    loading={states.isUpdatingForm.value}
                    onClick={() => sendCurrentAdjusterInfo(false, servicePictures.pictures, 'services').then(() => serviceNavigation(ClaimPersonManagementFacade.SERVICE_NAVIGATION_PARAMS.LIST, undefined, false))}
                  >
										{ states.serviceToEdit ? 'Editar' : 'Crear'}
									</Button>

									{/* <Button type="button" onClick={() => sendCurrentAdjusterInfo(false, servicePictures.pictures, 'services').then(() => serviceNavigation(ClaimPersonManagementFacade.SERVICE_NAVIGATION_PARAMS.LIST, undefined, false))}>
										{ states.serviceToEdit ? 'Editar' : 'Crear'}
									</Button> */}
								</div>
							</div>
						</ClaimContent>
					}
        </>
      }
    </div>
  );
};

export default ClaimPersonServicePage;