/**
 * 
 */
export const transform = {

  DATE: {
    serverStringDateToClientDate: (serverStringDate: string): Date => {
      const values = serverStringDate.split('-');
      let date = new Date();
      date.setFullYear(parseInt(values[0]));
      date.setMonth(parseInt(values[1]) - 1);
      date.setDate(parseInt(values[2]));
      return date;
    },
    dateTime: (date: Date): string => {
      let _value: string = '';
      if (date) {
        _value = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}/${(date.getMonth() + 1) < 10 ? '0' : ''}${(date.getMonth() + 1)}/${date.getFullYear()}`;
        // Set hours (base 12)
        _value = `${_value} ${date.getHours() > 12? `${(date.getHours() - 12) >= 10? (date.getHours() - 12) : `0${(date.getHours() - 12)}`}`: (date.getHours() >= 10? date.getHours() : date.getHours() === 0? '12' : `0${date.getHours()}`) }:`;
        // Set minutes
        _value = `${_value}${date.getMinutes() < 10? `0${date.getMinutes()}` : date.getMinutes()}`;
        // Set am / pm
        _value = `${_value} ${date.getHours() > 11 && date.getHours() < 24? 'PM' : 'AM'}`;
      } else if (date === undefined || date === null) {
        _value = '--';
      }

      return _value;
    },
    formatDate: (date: Date): string => {
      let _value: string = '';
      if (date) {
        _value = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}/${(date.getMonth() + 1) < 10 ? '0' : ''}${(date.getMonth() + 1)}/${date.getFullYear()}`;
      } else if (date === undefined || date === null) {
        _value = '--';
      }

      return _value;
    },
    formatUTCDate: (date: Date): string => {
      let _value: string = '';
      if (date) {
        _value = `${date.getUTCDate() < 10 ? '0' : ''}${date.getUTCDate()}/${(date.getUTCMonth() + 1) < 10 ? '0' : ''}${(date.getUTCMonth() + 1)}/${date.getUTCFullYear()}`;
      } else if (date === null) {
        _value = '--';
      }

      return _value;
    },
		formatServerDate: (date: Date, splitter: string = '-'): string => {
			let _value: string = '';
			if (date) {
				_value = `${date.getFullYear()}${splitter}${(date.getMonth() + 1) < 10 ? '0' : ''}${(date.getMonth() + 1)}${splitter}${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
			} else {
				_value = '--';
			}

			return _value;
		},
		formatServerDateTime: (date: Date, splitter: string = '-'): string => {
			let _value: string = '';
			if (date) {
				_value = `${date.getFullYear()}${splitter}${(date.getMonth() + 1) < 10 ? '0' : ''}${(date.getMonth() + 1)}${splitter}${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
        _value = `${_value} ${date.getHours() < 10? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10? `0${date.getMinutes()}` : date.getMinutes()}:00 +0000`
			} else {
				_value = '--';
			}

			return _value;
		},
    formatUTCServerDateTime: (date: Date, splitter: string = '-'): string => {
			let _value: string = '';
			if (date) {
				_value = `${date.getUTCFullYear()}${splitter}${(date.getUTCMonth() + 1) < 10 ? '0' : ''}${(date.getUTCMonth() + 1)}${splitter}${date.getUTCDate() < 10 ? '0' : ''}${date.getUTCDate()}`;
        _value = `${_value} ${date.getUTCHours() < 10? `0${date.getUTCHours()}` : date.getUTCHours()}:${date.getUTCMinutes() < 10? `0${date.getUTCMinutes()}` : date.getUTCMinutes()}:00 +0000`
			} else {
				_value = '--';
			}

			return _value;
		}
  },

  NUMBER: {
    formatNumberToString: (number: number): string => {
      let _value: string = '';

      if (number === 1000000) {
        _value = `${number.toString()[0]} millón`;
      } else if (number > 1000000) {
        _value = `${number.toString()[0]}${number.toString()[1] !== '0' ? `.${number.toString()[1]}` : ''} millones`;
      } else if (number < 1000000 && number > 1000) {
        _value = `${number / 1000} mil`;
      }
      return _value;
    },
  },
  CURRENCY: {
    MXN: (value: number): string => {
      const currencyFormater = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });

      return `${currencyFormater.format(value)}`;
    }
  },
  TEXT: {
    firstLetterUpperCase: (value: string): string => {
      const splitedValue: string[] = value.split(' ');
      let upperCaseValue: string = '';
      let index: number = 0;
      for (let part of splitedValue) {
        upperCaseValue = `${upperCaseValue}${part.charAt(0).toLocaleUpperCase()}${part.slice(1).toLowerCase()}${index < (splitedValue.length - 1) ? ' ' : ''}`;
        index++;
      }
      return upperCaseValue;
    },
    normalize: (value: string): string => {
      const normalizeForm = 'NFD';
      const marksRegex = /[\u0300-\u036f]/g;
      const normalizedValue = value.normalize(normalizeForm).replace(marksRegex, '');
      return normalizedValue;
    }
  },
  TIMER: {
    coutndownBySeconds: (seconds: number): string => {
      let countdown: string = '';
      if (seconds >= 0) {
        countdown = `${seconds / 60 < 10 ? '0' : ''}${Math.floor(seconds / 60)}:${(seconds % 60) < 10 ? '0' : ''}${seconds % 60}`;
      }
      return countdown;
    }
  },
  FILE: {
    toBase64: (file: File) => new Promise<string>(
      (resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
      }
    ),
    dataURLtoFile: (dataurl: string) => {
      var arr = dataurl.split(','),
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], 'photo');
  
    }
  }
}