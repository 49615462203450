const BASE_URL: string = '/claims';

/**
 * 
 */
export const CLAIM_REGISTER_FORM_TYPE = {
  NA: 'internal',
  THIRD_PARTY: 'external'
}

/**
 * 
 */
export const CLAIM_REGISTER_PERSON_PARAMS = {
  WELCOME: 'welcome',
  NAME: 'name',
  CONTACT: 'contact',
  DESCRIPTION: 'description',
  GENERIC_PICTURES: 'generic-pictures',
  LOCATION: 'location',
  LICENSE: 'license',
  LICENSE_PICTURES: 'license-pictures',
  DRIVING_PERMISSION: 'driving-permission',
  VEHICLE_DATA: 'vehicle-data',
  VEHICLE_VIN: 'vehicle-vin',
  VEHICLE_PICTURES: 'vehicle-pictures',
  DAMAGE: 'damage',
  SUMMARY: 'summary',
  SIGNATURE: 'signature',
  COMPLETED: 'completed'
}

export const CLAIM_ADJUSTER_PERSON_MANAGEMENT_PARAMS = {
	GENERAL: 'general',
	PERSONS_LIST: 'persons-list',
	PERSON_TYPE: 'person-type',
	VEHICLE: 'vehicle',
	DAMAGE: 'damage',
	SERVICE: 'service',
	SIGNATURE: 'signature',
	EDIT: 'edit',
  SYNC: 'sync'
}

export const CLAIM_ADJUSTER_PARAMS = {
  INIT: 'init',
  INFO: 'info',
  PANEL: 'panel',
  DECLARATION: 'declaration',
	DECLARATION_SIGNATURE: 'declaration-signature',
  PERSON_MANAGEMENT: 'person-management',
	CLOSURE: 'closure',
	CLAIM_SIGNATURE: 'signature',
  COMPLETED: 'completed'
}

export const CLAIM_ADJUSTER_PERSON_EDIT_PARAMS = {
	DATA: 'data',
	LIST: 'list',
	PERSONAL: 'personal',
	LICENSE_PICTURES: 'license-pictures',
	INE: 'ine',
	DRIVING_CARD: 'driving-card',
	LICENSE_DATA: 'license-data',
	INSURANCE_DATA: 'insurance',
	VEHICLE_PICTURES: 'pictures',
	VEHICLE_VIN: 'vin',
	SERVICE_TYPE: 'type',
}

/**
 * 
 */
 export const CLAIM_REGISTER_PATH = {
  CLAIMS: BASE_URL,
  FORMS: `${BASE_URL}/forms`,
  ADJUSTER: `${BASE_URL}/adjuster`,
  FORM_DISABLED: `${BASE_URL}/completed`
}