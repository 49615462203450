import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { ClaimRegisterSlice } from "../slices/claim-register.slice";
import { ClaimRegisterSaga } from "../sagas/claim-register.saga";
import { ClaimRegisterAction } from "../actions/claim-register.action";
import { ClaimRegisterSelector } from "../selectors/claim-register.selector";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingFactory } from "src/factory/loading.factory";
import { CLAIM_ADJUSTER_PARAMS, CLAIM_REGISTER_PATH, CLAIM_REGISTER_PERSON_PARAMS } from "src/routes/paths/claim-register.paths";

/**
 *
 */
export namespace ClaimRegisterFacade {

  /**
   * 
   */
  export const useClaimRegister = () => {
    useInjectReducer({ key: 'claims', reducer: ClaimRegisterSlice.reducer });
    useInjectSaga({ key: 'claimRegister', saga: ClaimRegisterSaga.claimRegisterSaga });

    const { formType, formId, key, pageName, sectionName } = useParams<string>();
    const registerInfo = useSelector(ClaimRegisterSelector.registerInfo);
    const adjusterInfo = useSelector(ClaimRegisterSelector.adjusterInfo);
    const formDisabled = useSelector(ClaimRegisterSelector.formDisabled);
    const location = useLocation();
    const loading = LoadingFactory.useLoader(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /**
     * 
     */
    const hideLoader = useCallback(
      (type: 'form' | 'adjuster') => {
        if (loading.value) {
          setTimeout(
            () => {
              loading.set(false);
              if (type === 'form' && pageName === undefined) {
                navigate(`${CLAIM_REGISTER_PATH.FORMS}/${formType}/${CLAIM_REGISTER_PERSON_PARAMS.WELCOME}/${formId}/${key}`, { replace: true });
              } else if (type === 'adjuster' && sectionName === undefined) {
                navigate(`${CLAIM_REGISTER_PATH.ADJUSTER}/${CLAIM_ADJUSTER_PARAMS.INIT}/${formId}/${key}`, { replace: true });
              }
            }, 1000
          );
        }
      },
      [loading, pageName, sectionName, formType, formId, key, navigate]
    );

    /**
     * 
     */
    useEffect(
      () => {
        if (formDisabled) {
          setTimeout(
            () => {
              loading.set(false);
              navigate(`${CLAIM_REGISTER_PATH.FORM_DISABLED}`, { replace: true });
            }, 1000
          );
        }
      }, [formDisabled, navigate, loading]
    )

    /**
     * 
     */
    useEffect(
      () => {
        if (formType && formId && key) {
          dispatch(ClaimRegisterAction.claimPersonForm({ id: formId, key: key }));
        }
      }, [formType, dispatch, formId, key]
    )

    /**
     * 
     */
    useEffect(
      () => {
        if (location.pathname.includes(CLAIM_REGISTER_PATH.ADJUSTER) && formId && key && !adjusterInfo) {
          dispatch(ClaimRegisterAction.claimAdjusterForm({ id: formId, key: key }))
        }
      }, [location, formId, key, dispatch, adjusterInfo]
    )

    /**
     * 
     */
    useEffect(
      () => {
        if (adjusterInfo && loading.value) {
          hideLoader('adjuster');
        }
      }, [adjusterInfo, loading, hideLoader]
    )

    /**
     * 
     */
    useEffect(
      () => {
        if (registerInfo && loading.value) {
          hideLoader('form');
        }
      }, [registerInfo, loading, hideLoader]
    );

    /**
     * 
     */
    useEffect(
      () => {
        dispatch(ClaimRegisterAction.fillLists());
      }, [dispatch]
    )

    /**
     * 
     */
    useEffect(
      () => {
        if (adjusterInfo) {
          dispatch(ClaimRegisterAction.fillListsWithPayload({adjuster: adjusterInfo}));
        }
      }, [adjusterInfo, dispatch]
    )

    return { 
      loading, 
      formDisabled
    }
  }
}