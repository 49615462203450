import { AxiosPromise } from "axios";
import { EnvironmentFactory } from "src/factory/EnvironmentFactory/environment.factory";
import { Util } from "src/utils/Util";
import { ICreateInvoiceRequest } from "../interfaces/ICreateInvoiceRequest";
import { IInvoiceDetailsData } from "../interfaces/IInvoiceDetailsData";
import { IInvoicePersonInfoData } from "../interfaces/IInvoicePersonInfoData";
import { ISaveInvoicePersonInfoRequest } from "../interfaces/ISaveInvoicePersonInfoRequest";
import { IValidateInvoiceData } from "../interfaces/IValidateInvoceData";
import { IInvoiceRequest } from "../interfaces/IInvoiceRequest";
import { ITaxRegimenData } from "../interfaces/ITaxRegimenData";
import { IInvoiceFileData } from "../interfaces/IInvoiceFileData";

/**
 *
 */
export namespace BillService {

  /**
   * 
   * @param request 
   * @returns 
   */
  export const validateInvoiceService = (request: IInvoiceRequest): AxiosPromise<IValidateInvoiceData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.JOURNEY).get(Util.API_PATH.VALIDATE_INVOICE(new Date().getTime()), {
      headers: {
        'Authorization': `Bearer ${request.token}`,
      },
      params: {
        product_id: request.productId
      }
    });
  }

  /**
   * 
   * @param id 
   * @returns 
   */
  export const invoiceFileService = (id: string): AxiosPromise<IInvoiceFileData> => {
    return EnvironmentFactory.Http.ExternalHttp(EnvironmentFactory.ENV.REACT_APP_INVOICE_FILE).get(`files?cfdi_id=${id}`);
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  export const invoiceDetailsService = (request: IInvoiceRequest): AxiosPromise<IInvoiceDetailsData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.JOURNEY).get(Util.API_PATH.INVOICE_DETAILS, {
      headers: {
        'Authorization': `Bearer ${request.token}`
      },
      params: {
        product_id: request.productId
      }
    });
  }

  /**
   * 
   * @returns 
   */
  export const invoicePersonInfoService = (token: string): AxiosPromise<IInvoicePersonInfoData> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.JOURNEY).get(Util.API_PATH.INVOICES_INFO, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  export const saveInvoicePersonInfoService = (request: ISaveInvoicePersonInfoRequest): AxiosPromise<void> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.JOURNEY).post(Util.API_PATH.SAVE_INVOICE_INFO, request);
  }

  /**
   * 
   * @param request 
   * @returns 
   */
  export const createInvoiceService = (request: ICreateInvoiceRequest, token: string): AxiosPromise<void> => {
    return EnvironmentFactory.Http.PublicHttp(EnvironmentFactory.ENV_DOMAIN_NAMES.JOURNEY).post(Util.API_PATH.CREATE_INVOICE, request,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
  }

  /**
   * 
   * @returns 
   */
  export const taxRegimesList = (): AxiosPromise<ITaxRegimenData> => {
    return EnvironmentFactory.Http.PublicHttp().get(Util.API_PATH.POLICY_TAX_REGIMES);
  }
}