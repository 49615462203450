
import QRCode from "react-qr-code";
import { ButtonLink, CopyClipboard, Spinner } from "src/components";
import { PolicyPicturesFacade } from 'src/modules/policy/controller/facades/policy-pictures.facade';
import { PolicyFacade } from 'src/modules/policy/controller/facades/policy.facade';
import { IconFactory } from "src/factory/icon.factory";

import './PolicyPictures.scss';


/**
 *
 */
const PolicyPicturesPage = (): JSX.Element => {
  const { selectedPolicy } = PolicyFacade.usePolicyManagement();
  const { currentDomain, params, isMobile, path } = PolicyPicturesFacade.usePolicyPictures(selectedPolicy);

  return (
    <div id="policy-pictures-wrapper" className="relative height-100">
      {
        selectedPolicy && currentDomain !== '' && params !== '' ?
          <>
            <div className="flex">
              <section>
                <p>
                  Para que tu póliza <b>{selectedPolicy?.policyNumber}</b> sea válida en caso de un siniestro necesitamos conocer tu auto previamente.
                  Subir tus fotograf&iacute;as es un requisito para poder tener nuestras coberturas.
                </p>
                {
                  !isMobile &&
                  <>
                    <h2>
                      Utiliza un tel&eacute;fono o dispositivo m&oacute;vil
                    </h2>
                    <p>
                      Escanea el código QR situado al final de esta pantalla
                      o bien copia este&nbsp;
                      <CopyClipboard
                        text={`${currentDomain}?${params}`}
                        message="Enlace copiado.">enlace
                      </CopyClipboard>
                      &nbsp;y p&eacute;galo en un dispositivo con c&aacute;mara para proceder a la captura de las fotos
                    </p>
                  </>

                }
                {
                  isMobile &&
                  <ButtonLink icon={IconFactory.cameraIcon()} className="insurance-pictures-link" to={`${path}?${params}`}>Tomar fotos</ButtonLink>
                }
              </section>
              {
                !isMobile &&
                <section className="flex align-center">
                  <div className="qr-wrapper flex column align-center">
                    <QRCode value={`${currentDomain}?${params}`} size={240} />
                  </div>
                </section>
              }

            </div>

          </>
          :
          <Spinner color="main" circles />
      }

    </div>
  );
};

export default PolicyPicturesPage;