import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Address } from "src/classes/Address";
import { StatusTypeItem } from "src/classes/claims/StatusTypeItem";
import { IClaimsStateReducer } from "src/store/states/modules-state/IClaimsStateReducer";
import { ClaimRegister } from "src/classes/claims/ClaimRegister";
import { ClaimAdjuster } from "src/classes/claims/ClaimAdjuster";

/**
 *
 */
export namespace ClaimRegisterSlice {

  const initialState: IClaimsStateReducer = {
    policyList: undefined,
    claimList: undefined,
    licenceTypes: [],
    vehicleColors: [],
    vehicleDamagesList: [],
    bikeDamagesList: [],
		serviceTypesList: [],
    vehicleTypes: [],
		personTypes: [],
		cancellingReasonsList: [],
		coveragesList: [],
    causesList: [],
    circumstancesList: [],
    liabilityList: [],
    states: [],
    claimRegister: undefined,
    claimAdjuster: undefined,
    formDisabled: false,
  };

  /**
   * 
   * @param state 
   * @param action 
   */
  const clear: CaseReducer<IClaimsStateReducer> = (state) => {
    state.licenceTypes = [];
    state.vehicleDamagesList = [];
    state.bikeDamagesList = [];
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const licenceTypes: CaseReducer<IClaimsStateReducer, PayloadAction<StatusTypeItem[]>> = (state, action) => {
    state.licenceTypes = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const vehicleColors: CaseReducer<IClaimsStateReducer, PayloadAction<StatusTypeItem[]>> = (state, action) => {
    state.vehicleColors = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const vehicleDamagesList: CaseReducer<IClaimsStateReducer, PayloadAction<StatusTypeItem[]>> = (state, action) => {
    state.vehicleDamagesList = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const bikeDamagesList: CaseReducer<IClaimsStateReducer, PayloadAction<StatusTypeItem[]>> = (state, action) => {
    state.bikeDamagesList = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const vehicleTypes: CaseReducer<IClaimsStateReducer, PayloadAction<StatusTypeItem[]>> = (state, action) => {
    state.vehicleTypes = action.payload;
  }

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const personTypes: CaseReducer<IClaimsStateReducer, PayloadAction<StatusTypeItem[]>> = (state, action) => {
		state.personTypes = action.payload;
	}

	/**
	 * 
	 * @param state 
	 * @param action 
	 */
	const serviceTypesList: CaseReducer<IClaimsStateReducer, PayloadAction<StatusTypeItem[]>> = (state, action) => {
		state.serviceTypesList = action.payload;
	}

  /**
   * 
   * @param state 
   * @param action 
   */
  const causesList: CaseReducer<IClaimsStateReducer, PayloadAction<StatusTypeItem[]>> = (state, action) => {
    state.causesList = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const circumstancesList: CaseReducer<IClaimsStateReducer, PayloadAction<StatusTypeItem[]>> = (state, action) => {
    state.circumstancesList = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const liabilityList: CaseReducer<IClaimsStateReducer, PayloadAction<StatusTypeItem[]>> = (state, action) => {
    state.liabilityList = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const cancellingReasonsList: CaseReducer<IClaimsStateReducer, PayloadAction<StatusTypeItem[]>> = (state, action) => {
    state.cancellingReasonsList = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const claimRegister: CaseReducer<IClaimsStateReducer, PayloadAction<ClaimRegister>> = (state, action) => {
    state.claimRegister = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const claimAdjuster: CaseReducer<IClaimsStateReducer, PayloadAction<ClaimAdjuster>> = (state, action) => {
    state.claimAdjuster = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const formDisabled: CaseReducer<IClaimsStateReducer, PayloadAction<boolean>> = (state, action) => {
    state.formDisabled = action.payload;
  }

  /**
   * 
   * @param state 
   * @param action 
   */
  const states: CaseReducer<IClaimsStateReducer, PayloadAction<Address[]>> = (state, action) => {
    state.states = action.payload;
  }

  const reducers = {
    clear,
    licenceTypes,
    vehicleColors,
    vehicleDamagesList,
    bikeDamagesList,
		serviceTypesList,
    causesList,
    circumstancesList,
    liabilityList,
    vehicleTypes,
		personTypes,
    states,
    claimRegister,
    claimAdjuster,
    cancellingReasonsList,
    formDisabled
  };

  /**
   * 
   */
  const slice = createSlice({
    name: 'claims',
    initialState,
    reducers: reducers,
  });

  export const { reducer, actions } = slice;
}